import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { GenericLambdaReturn } from '../models/partsWarehouse';
import { PrepackSummaryService, PrepackSummaryType } from '../services/prepack-summary.service';
import { Router } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { DatePickerVisibilityService } from '../services/date-picker-visibility.service';

@Component({
  selector: 'app-prepack-summary',
  templateUrl: './prepack-summary.component.html',
  styleUrls: ['./prepack-summary.component.scss']
})
export class PrepackSummaryComponent implements OnInit, OnDestroy {

  dataSource:MatTableDataSource<PrepackSummaryType>
  mDataSource:PrepackSummaryType[];
  columns: string[]=["LOCATION", "CONDITION", "WORK_ORDERS", "PIECES"];
  @ViewChild(MatSort) sort: MatSort;
  subscription: Subscription;
  isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
  filter: string = '';
  totalsObj: object = {}; 

  constructor(
    private service: PrepackSummaryService,
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private datePickVis: DatePickerVisibilityService
  ) { }

  ngOnInit() {
    this.datePickVis.updateDatePickerVis(false)
    this.dataSource = new MatTableDataSource<PrepackSummaryType>();
    this.dataSource.sort=this.sort;
    this.fetch();
    this.route.paramMap.subscribe (params => {
      this.filter = params.get("filter")
      console.log(this.filter)
      this.applyFilter(this.filter)
    })
  }

  fetch() {
    let d:Observable<GenericLambdaReturn<PrepackSummaryType[]>> =
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          this.service.displayPrepackSummary())
        );
    if (this.subscription) this.subscription.unsubscribe();
    this.subscription=d.subscribe((next) => {
      this.totalsObj = next.parts.find(obj => {return obj.LOCATION === "TOTAL"})
      this.dataSource.data=next.parts.filter(function(obj){return obj.LOCATION !== "TOTAL"})
      this.mDataSource = this.dataSource.filteredData;
      this.dataSource.sort=this.sort;
    })
  }

  prepackDetail(buildLocation: string, condition: string){
    this.router.navigate(['/prepackdetail', buildLocation, condition]);
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getTotalWorkOrders(){
    return this.dataSource.data.map(t => t.WORK_ORDERS).reduce((acc, value) => acc + value, 0)
  }

  getTotalPieces(){
    return this.dataSource.data.map(t => t.PIECES).reduce((acc, value) => acc + value, 0)
  }

}
