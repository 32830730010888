import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap,merge } from 'rxjs/operators';
import { GenericLambdaReturn,  DateFilter } from '../models/partsWarehouse';
import { PartsServiceUtil } from './parts.service.util';
import { AbstractRestService } from './abstract.rest.service'
@Injectable({
  providedIn: 'root'
})
export class SummaryBySiteService extends AbstractRestService {

  dfCompare( a: DateFilter, b:DateFilter): boolean {
    console.log("dfCompare",a,b);
    if (!(a||b)) return true;
    if (! a&&b ) return false;
    return !(a.start === b.start && a.end===b.end);
  }

  completedByTransportMode(df: DateFilter):Observable<GenericLambdaReturn<SumByTransportMode[]>> {
    return this.get('/summary', PartsServiceUtil.buildParams(null,df)).pipe(
       tap(o=>o.parts.forEach(p=>p.status==="Completed"))
    );
  }

  openByTransportMode(df:DateFilter):Observable<GenericLambdaReturn<SumByTransportMode[]>> {
    return this.get('/partsduetoday', PartsServiceUtil.buildParams(null,df)).pipe(
       tap(o=>o.parts.forEach(p=>p.status==="Open"))
    );
  }

}

export class SumByTransportMode {
  site: string;
  status?: string;
  transportMode?: string;
  Parcel_Orders?: number;
  Truck_Orders?: number;
  Parcel_Items?: number;
  Truck_Items?: number;
  Parcel_Pieces?: number;
  Truck_Pieces?: number;
}
