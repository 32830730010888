import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DateSelectComponent } from '../date-select/date-select.component';
import { ProductivityService } from '../productivity.service';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  datasource:any[];

  subSummary$:Subscription;

  displayedColumns: string[] = [ 'single_lines', 'single_pieces', 'single_hours','single_lph', 'single_performance', 'multi_lines', 'multi_pieces', 'multi_hours','multi_lph', 'multi_performance', 'ltl_lines', 'ltl_pieces', 'ltl_hours','ltl_lph', 'ltl_performance', 'total_lines', 'total_pieces', 'total_hours','total_lph', 'total_performance' ];
  dataColumns: string[] =  [ 'username', 'single_lines', 'single_pieces', 'single_hours','single_lph', 'single_performance', 'multi_lines', 'multi_pieces', 'multi_hours','multi_lph', 'multi_performance', 'ltl_lines', 'ltl_pieces', 'ltl_hours','ltl_lph', 'ltl_performance', 'total_lines', 'total_pieces', 'total_hours','total_lph', 'total_performance'];
  displaySectionColumns: string[] = ['username', 'single_header','multi_header','ltl_header', 'total_header'];
  
  shiftFormGroup:UntypedFormGroup;
  shiftList:string[] = ['1ST SHIFT','2ND SHIFT','3RD SHIFT'];

  shiftDate:string;
  shift:string;
  selectedShift:string="1ST SHIFT";

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private prodservice:ProductivityService
  ) { 
    this.shiftFormGroup = this.fb.group({
      Shift: new UntypedFormControl({value: "1ST SHIFT", disabled: false}, Validators.required)
    });    
  }

  ngOnInit(): void {
    this.getData();
  }

  ngOnDestroy() {
    if (this.subSummary$) this.subSummary$.unsubscribe();
  }  

  getData():void {
    if (this.subSummary$) this.subSummary$.unsubscribe();

    this.subSummary$ = this.prodservice.getUserSummary().subscribe(
      (out) => {
        console.log("Retrieved summary!",out);
        this.datasource = out.Body;
        this.shiftDate = this.prodservice.shiftDate;
        this.shift = this.prodservice.shift;
      }
    )
  }

  onDateSelection(e) {
    console.log("onDateSelection", e);
  }

  onShiftSelection(e) {
    console.log("onShiftSelection", e);
  }

  changeShiftDate() {
    console.log("changeShiftDate");

    const dialogRef = this.dialog.open(DateSelectComponent, {
      width: '300px',
      maxHeight: '800px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Close ShiftSelectDialogComponent");
      this.getData();
    });
  }

  navToUserHourSummary(sso:string) {
    this.router.navigate(['/userproductivity/hour', sso]);
  }

}
