<form class="hourlyView" [formGroup] = "hourlyViewFormGroup">
  <mat-form-field class="hourSelect">
    <mat-label>Hour</mat-label>
    <mat-select formControlName="hourControl">
      <mat-option *ngFor="let hour of hours" [value]="hour.value">
        {{hour.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>

<table mat-table [dataSource]="dataSource" matSort matSortActive="LicensePlates"
    matSortDirection="desc" matSortStart="desc" matSortDisableClear>
  <ng-container matColumnDef="Name" matSortStart="asc">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let d">{{d.Name}}</td>
  </ng-container>
  <ng-container matColumnDef="LicensePlates">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>LPNs</th>
    <td mat-cell *matCellDef="let d">{{d.LicensePlates | number:'1.0-0'}}</td>
  </ng-container>
  <ng-container matColumnDef="Pieces">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Pieces</th>
    <td mat-cell *matCellDef="let d">{{d.Pieces | number:'1.0-0'}}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" [ngClass]="{warning : (row.LABELS / row.Orders) > 1.25}" (click)="shipmentDetails(row.SSO)"></tr>
</table>
