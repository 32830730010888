import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericLambdaReturn, DateFilter } from '../models/partsWarehouse';
import { PartsServiceUtil } from './parts.service.util';
import { AbstractRestService } from './abstract.rest.service'
@Injectable({
  providedIn: 'root'
})
export class ReceivingLpnUserService extends AbstractRestService{

  dfCompare( a: DateFilter, b:DateFilter): boolean {
    console.log("dfCompare",a,b);
    if (!(a||b)) return true;
    if (! a&&b ) return false;
    return !(a.start === b.start && a.end===b.end);
  }

  getReceivingLpnByUserData(siteId: string, df: DateFilter, hour?: number):Observable<GenericLambdaReturn<ReceivingLpnData[]>> {
    return this.get('/receivinglpnbyuser', PartsServiceUtil.buildParams(siteId, df, null, null, hour));
  }
}

export type ReceivingLpnData = {
  SSO: number;
  Name: string;
  licensePlates: number;
  Pieces: number;
}
