import { Injectable } from '@angular/core';
import { AbstractRestService } from './abstract.rest.service';
import { Observable } from 'rxjs';
import { GenericLambdaReturn } from '../models/partsWarehouse';
import { PartsServiceUtil } from './parts.service.util';

@Injectable({
  providedIn: 'root'
})
export class PackMetricTotalService extends AbstractRestService{

  // this is suboptimal - No Type Checking - could replace any with PackTotal[]
  getPackMetricTotalData():Observable<GenericLambdaReturn<any>> {
    return this.get('/packmetrictotal', PartsServiceUtil.buildPackMetricUserParams())
  }
}

export type PackTotal = {
  Hour : number,
  Lines : number,
  Users : number,
  Rate : number
}