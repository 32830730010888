import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RollupByXportModeComponent } from './rollup-by-xport-mode/rollup-by-xport-mode.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WorkerPackMetricsComponent } from './worker-pack-metrics/worker-pack-metrics.component';
import { WorkerDetailComponent } from './worker-pack-detail/worker-pack-detail.component';
import { OrderChaseComponent } from './order-chase/order-chase.component';
import { SalesOrdersComponent } from './sales-orders/sales-orders.component';
import { DateRangeSelectorService } from './services/date-range-selector.service';
import { ExportModeSummaryComponent } from './export-mode-summary/export-mode-summary.component';
import { PrepackSummaryComponent } from './prepack-summary/prepack-summary.component';
import { PrepackDetailComponent } from './prepack-detail/prepack-detail.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupByPipe } from './pipes/group.pipe';
import { PrepackMetricComponent } from './prepack-metric/prepack-metric.component';
import { ReceivingLpnUserComponent } from './receiving-lpn-user/receiving-lpn-user.component';
import { PrepackMetricDetailComponent } from './prepack-metric-detail/prepack-metric-detail.component';
import { PutawayMetricComponent } from './putaway-metric/putaway-metric.component';
import { SpeedOfShipmentComponent } from './speed-of-shipment/speed-of-shipment.component';
import { PickMetricUserComponent } from './pick-metric-user/pick-metric-user.component';
import { PickMetricTotalComponent } from './pick-metric-total/pick-metric-total.component';
import { PackMetricTotalComponent } from './pack-metric-total/pack-metric-total.component';
import { SummaryComponent } from './user-productivity/summary/summary.component';
import { SummaryHourComponent } from './user-productivity/summary-hour/summary-hour.component';
import { DetailComponent } from './user-productivity/detail/detail.component';
import { DateSelectComponent } from './user-productivity/date-select/date-select.component';
import { GeaMesDatepickerModule } from '@gea-mes/datepicker';

/** 
 * Fusion Charts
 * 
 * Note 01/03/2023
 * 
 * For Angular 14, angular-fusioncharts module does not work. See issue:
 * 
 * https://github.com/fusioncharts/angular-fusioncharts/issues/153
 * 
 * Used the module copy from https://stackblitz.com/edit/angular-ivy-32bzyf
 * 
 * Had to have a copy of the module in the app itself which is why FusionChartsModule is local to the app.
 * 
 **/ 

import { FusionChartsModule } from './angular-fusioncharts/fusioncharts.module';
import * as FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import * as CandyTheme from 'fusioncharts/themes/fusioncharts.theme.candy';

@NgModule({
  declarations: [
    AppComponent,
    RollupByXportModeComponent,
    WorkerPackMetricsComponent,
    WorkerDetailComponent,
    OrderChaseComponent,
    SalesOrdersComponent,
    ExportModeSummaryComponent,
    PrepackSummaryComponent,
    PrepackDetailComponent,
    GroupByPipe,
    PrepackMetricComponent,
    ReceivingLpnUserComponent,
    PrepackMetricDetailComponent,
    PutawayMetricComponent,
    SpeedOfShipmentComponent,
    PickMetricUserComponent,
    PickMetricTotalComponent,
    PackMetricTotalComponent,
    SummaryComponent,
    SummaryHourComponent,
    DetailComponent,
    DateSelectComponent
  ],
  imports: [
    BrowserModule,
    FusionChartsModule.forRoot(FusionCharts, Charts, FusionTheme, CandyTheme),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatButtonModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatExpansionModule,
    MatTooltipModule,
    MatSortModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatInputModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    FusionChartsModule,
    MatDialogModule,
    GeaMesDatepickerModule
  ],
  providers: [DateRangeSelectorService
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
