<form class="hourlyView" [formGroup] = "hourlyViewFormGroup">
  <mat-form-field class="hourSelect">
    <mat-label>Hour</mat-label>
    <mat-select formControlName="hourControl">
      <mat-option *ngFor="let hour of hours" [value]="hour.value">
        {{hour.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>

<mat-card *ngFor="let d of data$ | async; trackBy: trackByIndex">
  <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="'190px'" [expandedHeight]="'190px'">
        <mat-card-title-group>
          <mat-card-title>
            <div class="container">
                <table >
                  <tr>
                    <th>Shipment ID</th>
                    <th>Total Volume</th>
                    <th>Total Weight</th>
                  </tr>
                  <tr>
                    <th>{{d.SHIPMENT_ID}}</th>
                    <th>{{d.TOTAL_VOLUME | number:'1.2-2'}}</th>
                    <th>{{d.TOTAL_WEIGHT | number:'1.2-2'}}</th>
                  </tr>
                </table>
              </div>
          </mat-card-title>
        </mat-card-title-group>
      </mat-expansion-panel-header>
      <mat-card-content>
          <table mat-table [dataSource]="d.ITEMS" matSortdisabled>
              <ng-container matColumnDef="ERP_ORDER">
                  <th mat-header-cell *matHeaderCellDef>Order #</th>
                  <td mat-cell *matCellDef="let d">{{d.ERP_ORDER}}</td>
              </ng-container>             
              <ng-container matColumnDef="CUST_CLASS">
                  <th mat-header-cell *matHeaderCellDef>Cust Class</th>
                  <td mat-cell *matCellDef="let d">{{d.CUST_CLASS}}</td>
              </ng-container>             
              <ng-container matColumnDef="Item">
                  <th mat-header-cell *matHeaderCellDef>Item</th>
                  <td mat-cell *matCellDef="let d">{{d.Item}}</td>
              </ng-container>               
              <ng-container matColumnDef="ITEM_HEIGHT">
                  <th mat-header-cell *matHeaderCellDef>Height</th>
                  <td mat-cell *matCellDef="let d">{{d.ITEM_HEIGHT | number:'1.2-2'}}</td>
              </ng-container>
              <ng-container matColumnDef="ITEM_WIDTH">
                  <th mat-header-cell *matHeaderCellDef>Width</th>
                  <td mat-cell *matCellDef="let d">{{d.ITEM_WIDTH | number:'1.2-2'}}</td>
              </ng-container>
              <ng-container matColumnDef="ITEM_LENGTH">
                  <th mat-header-cell *matHeaderCellDef>Length</th>
                  <td mat-cell *matCellDef="let d">{{d.ITEM_LENGTH | number:'1.2-2'}}</td>
              </ng-container>                            
              <ng-container matColumnDef="VOLUME">
                  <th mat-header-cell *matHeaderCellDef>Volume</th>
                  <td mat-cell *matCellDef="let d">{{d.VOLUME | number:'1.2-2'}}</td>
              </ng-container>
              <ng-container matColumnDef="WEIGHT">
                  <th mat-header-cell *matHeaderCellDef>Weight</th>
                  <td mat-cell *matCellDef="let d">{{d.WEIGHT | number:'1.2-2'}}</td>
              </ng-container>               
              <tr mat-header-row *matHeaderRowDef="columns"></tr>
              <tr mat-row *matRowDef="let row; columns: columns;"></tr>           
          </table>
      </mat-card-content>
    </mat-expansion-panel>

</mat-card>