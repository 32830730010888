import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatePickerVisibilityService {
  private datePickerVisibility$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  public datePickerVisibility: Observable<boolean> = this.datePickerVisibility$.asObservable();

  constructor() {}


  updateDatePickerVis(visible: boolean){
    this.datePickerVisibility$.next(visible)
  }
}
