import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericLambdaReturn } from '../models/partsWarehouse';
import { PartsServiceUtil } from './parts.service.util';
import { AbstractRestService } from './abstract.rest.service'

@Injectable({
  providedIn: 'root'
})
export class PrepackSummaryService extends AbstractRestService{
  
  displayPrepackSummary():Observable<GenericLambdaReturn<PrepackSummaryType[]>> {
    return this.get('/prepacksummary', PartsServiceUtil.buildParams(null, null, null, null, null, null));
  }
}

export type PrepackSummaryType = {
  LOCATION: string,
  CONDITION: string,
  WORK_ORDERS: number,
  PIECES: number
}
