<div *ngIf="(isScreen$ | async)?.matches">
  <table mat-table [dataSource]="dataSource" matSort
      matSortDirection="desc" matSortStart="desc" matSortDisableClear>
    <ng-container matColumnDef="BUILD_LOCATION">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Build Location</th>
      <td mat-cell *matCellDef="let d">{{d.BUILD_LOCATION}}</td>
    </ng-container>
    <ng-container matColumnDef="CREATED_DATE_TIME" matSortStart="asc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
      <td mat-cell *matCellDef="let d">{{d.CREATED_DATE_TIME | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD HH:mm:ss'}}</td>
    </ng-container>
    <ng-container matColumnDef="WORK_ORDER_ID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Work Order ID</th>
      <td mat-cell *matCellDef="let d">{{d.WORK_ORDER_ID}}</td>
    </ng-container>
    <ng-container matColumnDef="CONDITION">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Condition</th>
      <td mat-cell *matCellDef="let d">{{d.CONDITION}}</td>
    </ng-container>
    <ng-container matColumnDef="ITEM">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>FG Item</th>
      <td mat-cell *matCellDef="let d">{{d.ITEM}}</td>
    </ng-container>
    <ng-container matColumnDef="QTY_TO_BE_BUILT">
      <th mat-header-cell *matHeaderCellDef mat-sort-header># To Be Built</th>
      <td mat-cell *matCellDef="let d">{{d.QTY_TO_BE_BUILT | number: '1.0-0'}}</td>
    </ng-container>
    <ng-container matColumnDef="QTY_BUILT">
      <th mat-header-cell *matHeaderCellDef mat-sort-header># Built</th>
      <td mat-cell *matCellDef="let d">{{d.QTY_BUILT | number: '1.0-0'}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  </table>
  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons>
  </mat-paginator>
</div>
<div *ngIf="(isTablet$ | async)?.matches" class="card-list">
  <mat-card *ngFor="let d of mDataSource" class="cards card-details">
    <mat-card-header>
      <mat-card-title>ID: {{d.WORK_ORDER_ID }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ul> 
          <li>Build Location: {{d.BUILD_LOCATION}}</li>
          <li>Created: {{d.CREATED_DATE_TIME}}</li>
          <li>Condition: {{d.CONDITION }}</li>
          <li>Item: {{d.ITEM }}</li>
          <li># To Be Built: {{d.QTY_TO_BE_BUILT }}</li>
          <li># Built: {{d.QTY_BUILT }}</li>
      </ul>
    </mat-card-content>
  </mat-card>
</div>
