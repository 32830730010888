<form class="hourlyView" [formGroup] = "hourlyViewFormGroup">
  <div class="flex-container">
    <mat-form-field class="hourSelect">
      <mat-label>Hour</mat-label>
      <mat-select formControlName="hourControl">
        <mat-option *ngFor="let hour of hours" [value]="hour.value">
          {{hour.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="item">
      Target Percentage: 0%
    </div>
  </div>
</form>

<table mat-table [dataSource]="dataSource" matSort matSortActive="Name"
    matSortDirection="asc" matSortStart="desc" matSortDisableClear>
  <ng-container matColumnDef="Name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let d">{{d.Name}}</td>
  </ng-container>
  <ng-container matColumnDef="Total">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>LPNs</th>
    <td mat-cell *matCellDef="let d">{{d.Total | number:'1.0-0'}}</td>
  </ng-container>
  <ng-container matColumnDef="pctIncorrect">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Incorrect Put-Aways</th>
    <td mat-cell *matCellDef="let d" [ngClass]="{good : d.pctIncorrect <= 25,  warning : d.pctIncorrect > 25 && d.pctIncorrect <= 75, bad : d.pctIncorrect > 75}">{{d.pctIncorrect | number: '1.0-0'}}%</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"></tr>
</table>
