import { Component, OnInit } from '@angular/core';
import { OrderChaseService, OrderChase } from '../services/order-chase.service'
import { DateFilter, GenericLambdaReturn } from '../models/partsWarehouse';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { DatePickerVisibilityService } from '../services/date-picker-visibility.service';


@Component({
  selector: 'app-order-chase',
  templateUrl: './order-chase.component.html',
  styleUrls: ['./order-chase.component.scss']
})
export class OrderChaseComponent implements OnInit {

  sosData:OrderChase[]
  data$:Observable<OrderChase[]>;
  dateFilter: DateFilter
  trackByIndex = index => index;
  viewSelection: boolean = false;
  color: any;
  checked: boolean;
  disabled: boolean;

  onChange(value) {
    this.viewSelection = value.checked
  }
  

  constructor(
    private service: OrderChaseService,
    private route: ActivatedRoute,
    private router: Router,
    private datePickVis: DatePickerVisibilityService
  ) {}

  ngOnInit() {
    this.datePickVis.updateDatePickerVis(false)
    this.data$ =
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          this.service.orderChase(params.get('siteId'), this.dateFilter)
        ),
        map(r=>r.parts)
      );
  }

  salesOrders(siteId: string, plannedDate: string, custClass: string, priority:number){
    this.router.navigate(['/salesorders', siteId, plannedDate, custClass, priority])
  }

}
