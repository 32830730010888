import { Injectable } from '@angular/core';
import { AbstractRestService } from './abstract.rest.service';
import { Observable } from 'rxjs';
import { GenericLambdaReturn } from '../models/partsWarehouse';
import { PartsServiceUtil } from './parts.service.util';

@Injectable({
  providedIn: 'root'
})
export class PickMetricTotalService extends AbstractRestService{

  getPickMetricTotalData():Observable<GenericLambdaReturn<PickTotal[]>> {
    return this.get('/pickmetrictotal', PartsServiceUtil.buildPickMetricUserParams())
  }
}

export type PickTotal = {
  Hour: number,
  TotalPieces: number,
  TotalLines: number,
  LPH: number,
  Performance: number,
  Pickers: number
}