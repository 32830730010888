import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RollupByXportModeComponent } from './rollup-by-xport-mode/rollup-by-xport-mode.component';
import { WorkerPackMetricsComponent } from './worker-pack-metrics/worker-pack-metrics.component';
import { WorkerDetailComponent } from './worker-pack-detail/worker-pack-detail.component';
import { OrderChaseComponent } from './order-chase/order-chase.component';
import { SalesOrdersComponent } from './sales-orders/sales-orders.component';
import { PrepackSummaryComponent } from './prepack-summary/prepack-summary.component';
import { PrepackDetailComponent } from './prepack-detail/prepack-detail.component';
import { PrepackMetricComponent } from './prepack-metric/prepack-metric.component'
import { LocationStrategy,PathLocationStrategy, APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { ReceivingLpnUserComponent } from './receiving-lpn-user/receiving-lpn-user.component';
import { PrepackMetricDetailComponent } from './prepack-metric-detail/prepack-metric-detail.component';
import { PutawayMetricComponent } from './putaway-metric/putaway-metric.component';
import { SpeedOfShipmentComponent } from './speed-of-shipment/speed-of-shipment.component';
import { GeaMesLoginComponent, GeaMesCognitoAuthGuard, GeaMesCognitoModule, COGNITO_CONFIG, GeaMesAuthInterceptor, APPLICATION_NAME, ALLOW_SCREENAUTH, APIGATEWAY_CONFIG } from '@gea-mes/cognito';
import { environment } from 'src/environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PickMetricUserComponent } from './pick-metric-user/pick-metric-user.component';
import { PickMetricTotalComponent } from './pick-metric-total/pick-metric-total.component';
import { PackMetricTotalComponent } from './pack-metric-total/pack-metric-total.component';
import { SummaryComponent } from './user-productivity/summary/summary.component';
import { SummaryHourComponent } from './user-productivity/summary-hour/summary-hour.component';
import { DetailComponent } from './user-productivity/detail/detail.component';
import { appinfo } from './appinfo';

const appRoutes: Routes = [
  {path: 'login', component: GeaMesLoginComponent},
  //{path: 'logout', component: LogOutPageComponent},
  {
    path: '', canActivate: [GeaMesCognitoAuthGuard],
    children: [
      {path: '', redirectTo:'/home', pathMatch: 'full'},
      {path: 'home', component: RollupByXportModeComponent, data: {title: "Summary"}},
      {path: 'workers/:siteId', component: WorkerPackMetricsComponent, data: {title: "Pack Metrics"}},
      {path: 'workerdetail/:siteId/:sso', component: WorkerDetailComponent, data: {title: "Pack Label Detail"}},  
      {path: 'shipping/:siteId', component: OrderChaseComponent, data: {title: "Check Lanes"}},
      {path: 'salesorders/:siteId/:plannedDate/:custClass/:priority', component: SalesOrdersComponent, data: {title: "Sales Orders"}},
      {path: 'prepacksummary/:filter', component: PrepackSummaryComponent, data: {title: "Prepack Summary"}},
      {path: 'prepackdetail/:buildLocation/:filter', component: PrepackDetailComponent, data: {title: "Prepack Detail"}},
      {path: 'prepackmetric/:siteId', component: PrepackMetricComponent, data: {title: "Prepack Metric"}},
      {path: 'prepackmetricdetail/:siteId', component: PrepackMetricDetailComponent, data: {title: "Prepack Metric Detail"}},
      {path: 'receivinglpnuser/:siteId', component: ReceivingLpnUserComponent, data: {title: "Receiving"}},
      {path: 'putawaymetric/:siteId', component: PutawayMetricComponent, data: {title: "Put-Away Metric"}},
      {path: 'sos/:siteId', component: SpeedOfShipmentComponent, data: {title: "Speed of Shipment"}},
      {path: 'pickmetricuser', component: PickMetricUserComponent},
      {path: 'pickmetrictotal', component: PickMetricTotalComponent},
      {path: 'packmetrictotal', component: PackMetricTotalComponent},
      {path: 'userproductivity', component: SummaryComponent},
      {path: 'userproductivity/hour/:sso', component: SummaryHourComponent},
      {path: 'userproductivity/detail/:sso/:hour', component: DetailComponent}
    ]
  },

  {path: '**',  redirectTo:'/home' }
];

export function getBaseHref(platformLocation: PlatformLocation): string {
    return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  imports: [GeaMesCognitoModule, RouterModule.forRoot(appRoutes, {})],
  providers: [ 
    { provide: COGNITO_CONFIG, useValue: environment.cognitoOptions },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: GeaMesAuthInterceptor, multi: true },
    { provide: APPLICATION_NAME, useValue: appinfo.name.toUpperCase() },
    { provide: APIGATEWAY_CONFIG, useValue: environment.apiGateway },        
    {provide: APP_BASE_HREF, useFactory: getBaseHref, deps: [PlatformLocation]}
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
