import { Injectable } from '@angular/core';
import { AbstractRestService } from './abstract.rest.service';
import { Observable } from 'rxjs';
import { GenericLambdaReturn } from '../models/partsWarehouse';
import { PartsServiceUtil } from './parts.service.util';

@Injectable({
  providedIn: 'root'
})
export class PickMetricUserService extends AbstractRestService{

  getPickMetricUserData(Hour?: number):Observable<GenericLambdaReturn<PickMetric[]>> {
    return this.get('/pickmetricuser', PartsServiceUtil.buildPickMetricUserParams(Hour))
  }
}

export type PickMetric = {
  Picker: string,
  TotalLines: number,
  TotalParts: number,
  HoursWorked: number,
  LPH: number,
  Performance: number
}