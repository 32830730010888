import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { DatePickerVisibilityService } from '../services/date-picker-visibility.service';
import { PrepackMetricService, PrepackMetric } from '../services/prepack-metric.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { waitForAsync } from '@angular/core/testing';

@Component({
  selector: 'app-prepack-metric',
  templateUrl: './prepack-metric.component.html',
  styleUrls: ['./prepack-metric.component.scss']
})
export class PrepackMetricComponent implements OnInit {

  pphDataSource: any;
  pphChartData: any;
  ordersPastDueDataSource: any; 
  ordersPastDueChartData: any;
  pphChartConfig: any;
  ordersPastDueChartConfig: any;
  workOrders:MatTableDataSource<PrepackMetric>;
  d:Subscription;
  d2:Subscription;
  siteId: string;
  data: any;
  today: any = new Date();
  minutesLeft: number = (60 - this.today.getMinutes()) * 60000;
  timeout: any;
  interval: any;
  columns: string[]=["Type","minProcess", "minScheduled","totalOrders"];
  isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])

  constructor(
    private service: PrepackMetricService,
    private route: ActivatedRoute,
    private router: Router,
    private datePickVis: DatePickerVisibilityService,
    private breakpointObserver: BreakpointObserver
  ) { }

  ngOnInit() {
    this.datePickVis.updateDatePickerVis(false)
    this.workOrders = new MatTableDataSource<PrepackMetric>();
    this.siteId = this.route.snapshot.paramMap.get('siteId')
    this.getMetricData();
    this.getTargetPiecesData();
    this.timeout = setTimeout(() => { 
      this.getTargetPiecesData();
      this.checkHour();
    }, this.minutesLeft);
    this.getOrdersPastDueData();
  }

  checkHour() {
    this.interval = setInterval(() => { 
      this.getTargetPiecesData();
    }, 3600000);
  }

  prepackMetricDetail(siteId: string) {
    this.router.navigate(['/prepackmetricdetail', siteId]);
  }

  getMetricData() {
    if (this.d){
      this.d.unsubscribe()
    }

    this.d =
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          this.service.retrieveStats(this.siteId)
        ),
        map(r=>r.parts)
      )
      .subscribe((next) => {
        //this.dataSource.data = WorkerPackMetricsComponent.sortData(next.parts, this.sort);
        console.log(next);
        this.workOrders.data=next; //.parts;
        //this.workOrders.sort=this.sort;
      })
  }

  getTargetPiecesData() {
    this.service.retreivePPHData(this.siteId).subscribe((response)=> {

      this.pphChartData = response
      console.log(this.pphChartData)

      const xAxis = this.pphChartData.parts.xAxis
      const yAxisActualTotal = this.pphChartData.parts.yAxisActualTotal
      const yAxisTarget = this.pphChartData.parts.yAxisTarget

      this.pphDataSource = {
        "chart": {
          "caption": "Pieces Per Hour",
          "legendPosition": "right",
          "xAxisName": "Hour",
          "yAxisName": "Pieces",
          "theme": "fint",
          "showValues": "0",
        },
        "categories":[
          {
            "category": xAxis
          }
        ],
        "dataset": [
          {
            "seriesname": "Target Pieces",
            "data": yAxisTarget
          },
          {
            "seriesname": "Total Pieces",
            "data": yAxisActualTotal 
          }
        ]
      }
    }) 
  }
  getOrdersPastDueData() {
    this.service.retreiveOrdersPastDueData(this.siteId).subscribe((response)=> {

      this.ordersPastDueChartData = response

      const xAxis = this.ordersPastDueChartData.parts.xAxis
      const yAxisWorkOrders = this.ordersPastDueChartData.parts.yAxisWorkOrders
      const yAxisTotalQty = this.ordersPastDueChartData.parts.yAxisTotalQty

      this.ordersPastDueDataSource = {
        "chart": {
          "caption": "Orders Past Due",
          "legendPosition": "right",
          "xAxisName": "Due Date",
          "pYAxisName": "Work Orders",
          "sYAxisName": "Total Pieces",
          "theme": "fint",
        },
        "categories":[
          {
            "category": xAxis
          }
        ],
        "dataset": [
          {
            "seriesName": "Work Orders",
            "renderAs": "bar",
            "showValues": "0",
            "data": yAxisWorkOrders
          },
          {
            "seriesName": "Total Pieces",
            "renderAs": "line",
            "parentYAxis": "S",
            "showvalues": "0",
            "data": yAxisTotalQty
          }
        ]
      }
    })
  }

  ngOnDestroy() {
    this.d.unsubscribe();
  }
}
