import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap,merge } from 'rxjs/operators';
import { GenericLambdaReturn } from '../models/partsWarehouse';
import { PartsServiceUtil } from './parts.service.util';
import { AbstractRestService } from './abstract.rest.service';

@Injectable({
  providedIn: 'root'
})

export class PrepackMetricService extends AbstractRestService{

  retrieveStats(siteId: string):Observable<GenericLambdaReturn<PrepackMetric[]>> {
    return this.get('/prepackmetric', PartsServiceUtil.buildParams(siteId, null))
  }

  retreivePPHData(siteId:string):Observable<GenericLambdaReturn<PPHData[]>> {
    return this.get('/prepacktargetmetric', PartsServiceUtil.buildParams(siteId, null))
  }

  retreiveOrdersPastDueData(siteId:string):Observable<GenericLambdaReturn<OrdersPastDueData[]>> {
    return this.get('/prepackorderspastdue', PartsServiceUtil.buildParams(siteId, null))
  }
}

export type PrepackMetric = {
  minProcess: string;
  minScheduled: string;
  totalOrders: number;
}

export type PPHData = {
  rawData: Array<any>;
  xAxis: Array<any>;
  yAxisTarget: Array<any>;
  yAxisActual: Array<any>;
  yAxisActualTotal: Array<any>;
}

export type OrdersPastDueData = {
  rawData: Array<any>;
  xAxis: Array<any>;
  yAxisWorkOrders: Array<any>;
  yAxisTotalQty: Array<any>;
}