import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { GenericLambdaReturn } from '../models/partsWarehouse';
import { PrepackMetricDetailService, PrePackMetricDetailType } from '../services/prepack-metric-detail.service';
import { Router } from '@angular/router';
import { DatePickerVisibilityService } from '../services/date-picker-visibility.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-prepack-metric-detail',
  templateUrl: './prepack-metric-detail.component.html',
  styleUrls: ['./prepack-metric-detail.component.scss']
})
export class PrepackMetricDetailComponent implements OnInit, OnDestroy {

  dataSource: MatTableDataSource<PrePackMetricDetailType>
  mDataSource:PrePackMetricDetailType[];
  columns: string[]=["buildLocation", "minProcess", "minScheduled", "totalOrders"]
  @ViewChild(MatSort) sort: MatSort;
  d: Subscription;
  siteId: string;
  isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])

  
  constructor(
    private service: PrepackMetricDetailService,
    private route: ActivatedRoute,
    private router: Router,
    private datePickVis: DatePickerVisibilityService,
    private breakpointObserver: BreakpointObserver

  ) { }

  ngOnInit() {
    this.datePickVis.updateDatePickerVis(false)
    this.dataSource = new MatTableDataSource<PrePackMetricDetailType>();
    this.siteId = this.route.snapshot.paramMap.get('siteId');
    this.fetch();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  fetch() {
    let d:Observable<GenericLambdaReturn<PrePackMetricDetailType[]>> =
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          this.service.displayPrepackMetricDetail(this.siteId))
        );
    if (this.d) this.d.unsubscribe();
    this.d=d.subscribe((next) => {
      this.dataSource.data=next.parts;
      this.dataSource.sort=this.sort;
      this.mDataSource = next.parts;
    })
  }

  ngOnDestroy() {
   if(this.d) this.d.unsubscribe();
  }

}
