<div *ngIf="(isScreen$ | async)?.matches">
    <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="CREATION_DATE_TIME_STAMP">
        <th mat-header-cell *matHeaderCellDef>Created</th>
        <td mat-cell *matCellDef="let d"> {{d.CREATION_DATE_TIME_STAMP | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD HH:mm:ss'}}</td>
    </ng-container>
    <ng-container matColumnDef="PLANNED_SHIP_DATE">
        <th mat-header-cell *matHeaderCellDef>Planned Ship Date</th>
        <td mat-cell *matCellDef="let d"> {{d.PLANNED_SHIP_DATE}}</td>
    </ng-container>
    <ng-container matColumnDef="PRIORITY">
        <th mat-header-cell *matHeaderCellDef>Priority</th>
        <td mat-cell *matCellDef="let d"> {{d.PRIORITY}}</td>
    </ng-container>
    <ng-container matColumnDef="ORDER_TYPE">
        <th mat-header-cell *matHeaderCellDef>Order Type</th>
        <td mat-cell *matCellDef="let d"> {{d.ORDER_TYPE}}</td>
    </ng-container>
    <ng-container matColumnDef="SHIPMENT_ID">
        <th mat-header-cell *matHeaderCellDef>Shipment ID</th>
        <td mat-cell *matCellDef="let d"> {{d.SHIPMENT_ID}}</td>
    </ng-container>
    <ng-container matColumnDef="LINES">
        <th mat-header-cell *matHeaderCellDef>Lines</th>
        <td mat-cell *matCellDef="let d"> {{d.LINES}}</td>
    </ng-container>.
    <ng-container matColumnDef="CARRIER">
        <th mat-header-cell *matHeaderCellDef>Carrier</th>
        <td mat-cell *matCellDef="let d"> {{d.CARRIER}}</td>
    </ng-container>
    <ng-container matColumnDef="CUSTOMER_CATEGORY7">
        <th mat-header-cell *matHeaderCellDef>Dist Channel</th>
        <td mat-cell *matCellDef="let d"> {{d.CUSTOMER_CATEGORY7}}</td>
    </ng-container>
    <ng-container matColumnDef="LEADING_STS">
        <th mat-header-cell *matHeaderCellDef>Leading Status</th>
        <td mat-cell *matCellDef="let d"> {{d.LeadingStatus}}</td>
    </ng-container>
    <ng-container matColumnDef="TRAILING_STS">
        <th mat-header-cell *matHeaderCellDef>Trailing Status</th>
        <td mat-cell *matCellDef="let d"> {{d.TrailingStatus}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr> 
    </table>
</div>
<div *ngIf="(isTablet$ | async)?.matches" class="card-list">
    <mat-card *ngFor="let d of mDataSource" class="cards card-details">
      <mat-card-header>
            <mat-card-title>Priority: {{d.PRIORITY }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul> 
            <li>Created: {{d.CREATION_DATE_TIME_STAMP | amFromUtc | amLocal | amDateFormat: 'YYYY-MM-DD HH:mm:ss'}}</li>
            <li>Planned Ship Date: {{d.PLANNED_SHIP_DATE}}</li>
            <li>Order Type: {{d.ORDER_TYPE }}</li>
            <li>Shipment ID: {{d.SHIPMENT_ID }}</li>
            <li>Lines: {{d.LINES }}</li>
            <li>Carrier: {{d.CARRIER }}</li>
            <li>Dist Channel: {{d.CUSTOMER_CATEGORY7 }}</li>
            <li>Leading Status: {{d.LeadingStatus }}</li>
            <li>Trailing Status: {{d.TrailingStatus }}</li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
