import { Injectable } from '@angular/core';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductivityService extends AbstractRestService {

  postInit(): void {}

  private dateSelectionData:BehaviorSubject<DateSelection> = new BehaviorSubject<DateSelection>(null); 

  shiftDate:string = '2022-12-13'
  shift:string = '1ST SHIFT';
  startDate:string = '2022-12-05 6:00';
  endDate:string = '2022-12-06 15:00';

  changeDateNew(shiftDate:string, shift:string) {
    this.dateSelectionData.next({
      shiftDate: shiftDate,
      shift: shift,
      startDate: '2022-12-05 6:00',
      endDate: '2022-12-06 15:00'     
    });    
  }

  changeDate(startDate:string, endDate:string, shiftDate:string, shift:string) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.shiftDate = shiftDate;
    this.shift = shift;
  }

  getUserSummary():Observable<GenericLambdaReturn<any>>  {
    let params:any={};

    params.startDate = this.startDate;
    params.endDate = this.endDate;

    return this.get({
      ApiResource: "/productivity/usersummary",
      data: params,
      repeatCall: true,
      repeatRate: 30000
    });     
  }

  getUserHourSummary(sso:string):Observable<GenericLambdaReturn<any>>  {
    let params:any={};

    params.startDate = this.startDate;
    params.endDate = '2022-12-05 15:00';
    params.user = sso;

    return this.get({
      ApiResource: "/productivity/userhour",
      data: params,
      repeatCall: true,
      repeatRate: 30000
    });     
  }

  getUserDetail(sso:string, hour:number):Observable<GenericLambdaReturn<any>>  {
    let params:any={};

    params.startDate = this.startDate;
    params.endDate = this.endDate;
    params.user = sso;
    params.hour = hour;

    return this.get({
      ApiResource: "/productivity/userdetail",
      data: params,
      repeatCall: true,
      repeatRate: 30000
    });     
  }
}

export type DateSelection = {
  shiftDate:string;
  shift:string;
  startDate:string;
  endDate:string;
}