import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { DateFilter } from '../models/partsWarehouse';

@Injectable()
export class DateRangeSelectorService {

  private dateRangeSelectedSource = new ReplaySubject<DateFilter>(1);
  dateRangeSelected$ = this.dateRangeSelectedSource.asObservable();
  constructor() {
    this.dateRangeSelectedSource.next(null);
    console.log("SERVICE NEW");
  }

  selectDateRange(df:DateFilter) {
    this.dateRangeSelectedSource.next(df);
  }

}
