import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { hourModel } from '../worker-pack-detail/worker-pack-model';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { PutAwayData, PutawayMetricService } from '../services/putaway-metric.service';
import { ActivatedRoute } from '@angular/router';
import { DatePickerVisibilityService } from '../services/date-picker-visibility.service';
import { DateRangeSelectorService } from '../services/date-range-selector.service';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { switchMap, tap } from 'rxjs/operators';
import { DateFilter } from '../models/partsWarehouse';

@Component({
  selector: 'app-putaway-metric',
  templateUrl: './putaway-metric.component.html',
  styleUrls: ['./putaway-metric.component.scss']
})
export class PutawayMetricComponent implements OnInit, OnDestroy {

  d:Subscription;

  hours: hourModel[] = [
    {value: -2, viewValue: 'All Day'},
    {value: -1, viewValue: 'Current Hour'}
  ];
  public hourlyViewFormGroup = new UntypedFormGroup({
    hourControl: new UntypedFormControl()
  })
  hourObj: hourModel;
  timeToRefreshHourArray: number;
  hourlyView: boolean;
  timeFrame: any;
  todayDate: string;
  selectedHour: number;
  dataSource: MatTableDataSource<PutAwayData>;
  columns: string[]=["Name", "Total", "pctIncorrect"];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  siteId: string;

  constructor(
    private service: PutawayMetricService,
    private route: ActivatedRoute,
    private datePickVis: DatePickerVisibilityService,
    private dateRangeSelectorService: DateRangeSelectorService
   ) {
    dayjs.extend(timezone)
    dayjs.extend(utc)
   }

  ngOnInit() {
    this.datePickVis.updateDatePickerVis(false)
    this.dataSource = new MatTableDataSource<PutAwayData>();
    this.siteId = this.route.snapshot.paramMap.get('siteId')

    this.fetch();
    this.populateHourDropDown();
    setInterval(this.populateHourDropDown, this.timeToRefreshHourArray)

    this.hourlyViewFormGroup.valueChanges.subscribe( val => {
      this.fetch(val["hourControl"])
    })
    this.datePickVis.updateDatePickerVis(true)
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  populateHourDropDown(){
    let now = dayjs().hour();
    let hour = 0
    let minNow = dayjs().minute();
  
    this.timeToRefreshHourArray = (((60 - minNow) * (60)) * 1000)
  
    this.hours.length = 0
  
    this.hours.push({value: -2, viewValue: 'All Day'})
    this.hours.push({value: -1, viewValue: 'Current Hour'})
  
    for (hour = 0; hour <= now; hour++){
      let dateVal = (dayjs().hour(hour)).tz('America/New_York');
      let dateViewVal = dayjs().hour(hour);
  
      this.hourObj = {
        value: dateVal.hour(),
        viewValue: dateViewVal.startOf('hour').format('h:mm A')
      }
  
      this.hours.push(this.hourObj)
    }
  }

  timeTransformer(time: number): number{
    switch(time) {
      case(-1): return dayjs().tz('America/New_York').startOf('hour').hour();
      case(-2): return null;
      default: return time;
    }
  }

  fetch(hour?: number) {
    if (this.d){
      this.d.unsubscribe()
    }
    let timeFrame = this.timeTransformer(hour)

    this.d =
      this.dateRangeSelectorService.dateRangeSelected$.pipe(
        tap(df=>console.log("invoke worker pack metrics",df)),
        switchMap((df: DateFilter) =>
          this.service.getLPNCounts(this.siteId, df, timeFrame)
        )
      )
      .subscribe((next) => {
        //this.dataSource.data = WorkerPackMetricsComponent.sortData(next.parts, this.sort);
        console.log(next.parts)
        this.dataSource.data=next.parts;
        this.dataSource.sort=this.sort;
      })
  }

  ngOnDestroy() {
    this.d && this.d.unsubscribe();
  }

}
