import { DateFilter } from '../models/partsWarehouse'
import { HttpParams } from '@angular/common/http'
import * as dayjs from 'dayjs'

export class PartsServiceUtil {
  static buildParams(siteId: string, df: DateFilter, custClass?: string, plannedDate?: string, hour?: number, buildLocation?: string, sso?: string, priority?: string): HttpParams {
    let params:any={};
    if (df) {
      df.start ? params.startDate=dayjs(df.start).format('YYYY-MM-DD'):null;
      df.end ? params.endDate=dayjs(df.end).format('YYYY-MM-DD'):null;
    }

    custClass ? params.custClass= custClass.toString():null;
    plannedDate ? params.plannedDate= plannedDate.toString():null;
    buildLocation ? params.buildLocation = buildLocation.toString():null;
    sso ? params.sso = sso.toString():null;

    siteId ? params.site=siteId:null;

    hour ? params.hour = hour:null;

    priority ? params.priority = priority.toString():null;

    console.log("PARAMS", params);

    return new HttpParams({fromObject: params});
  }

  static buildPickMetricUserParams(Hour?: number): HttpParams {
    let params:any={};

    if (Hour != null)
    {
      params.Hour = Hour
    }
    return new HttpParams({fromObject: params});
  }

  static buildPackMetricUserParams(): HttpParams {
    let params:any={};
    return new HttpParams({fromObject: params});
  }

}

