import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericLambdaReturn, DateFilter } from '../models/partsWarehouse';
import { PartsServiceUtil } from './parts.service.util';
import { AbstractRestService } from './abstract.rest.service'

@Injectable({
  providedIn: 'root'
})
export class WorkpackmetricdetailService extends AbstractRestService {

  hourSumByWorker(siteId: string, df: DateFilter, hour: number):Observable<GenericLambdaReturn<WorkerStatsDetail[]>> {
    return this.get('/packmetric', PartsServiceUtil.buildParams(siteId,df,null, null, hour));
  }
}

export type WorkerStatsDetail = {
  SSO: number;
  Name: string;
  Orders: number;
  Lines: number;
  Pieces: number;
}
