<div #rollupByXportMode class="container">
  <mat-card *ngFor="let d of completed$ | async" (click)="workerPackMetrics(d.Site)">
    <mat-card-title-group>
      <mat-card-title>{{d.Site}}</mat-card-title>
      <mat-card-subtitle>Completed Orders</mat-card-subtitle>
    </mat-card-title-group>
    <mat-card-content>
      <app-export-mode-summary [metrics]="d"></app-export-mode-summary>
    </mat-card-content>
  </mat-card>
  <mat-card *ngFor="let d of open$ | async" (click)="orderChase(d.Site)">
    <mat-card-title-group>
      <mat-card-title>{{d.Site}}</mat-card-title>
      <mat-card-subtitle>Open Orders</mat-card-subtitle>
    </mat-card-title-group>
    <mat-card-content>
      <app-export-mode-summary [metrics]="d"></app-export-mode-summary>
    </mat-card-content>
  </mat-card>
</div>
