import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericLambdaReturn } from '../models/partsWarehouse';
import { PartsServiceUtil } from './parts.service.util';
import { AbstractRestService } from './abstract.rest.service';

@Injectable({
  providedIn: 'root'
})
export class PrepackDetailService extends AbstractRestService{

  displayPrepackDetail(buildLocation: string):Observable<GenericLambdaReturn<PrePackDetailType[]>> {
    return this.get('/prepackdetail', PartsServiceUtil.buildParams(null, null, null, null, null, buildLocation));

  }
}

export type PrePackDetailType = {
  BUILD_LOCATION: string,
  CREATED_DATE_TIME: Date,
  WORK_ORDER_ID: string,
  CONDITION: string,
  ITEM: string,
  QTY_TO_BE_BUILT: number,
  QTY_BUILT: number
}
