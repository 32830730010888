<div *ngIf="!isMobile; else mobileView">
    <mat-tab-group>
        <mat-tab label="Table">
            <ng-template matTabContent>
                <table mat-table [dataSource]="dataSourceTable">

                    <ng-container matColumnDef="HOUR">
                        <th mat-header-cell *matHeaderCellDef>Hour</th>
                        <td mat-cell *matCellDef="let element">{{element.HOUR}}</td>
                    </ng-container>

                    <ng-container matColumnDef="TOTAL_PIECES">
                        <th mat-header-cell *matHeaderCellDef>Total Pieces</th>
                        <td mat-cell *matCellDef="let element">{{element.TOTAL_PIECES}}</td>
                    </ng-container>

                    <ng-container matColumnDef="TOTAL_LINES">
                        <th mat-header-cell *matHeaderCellDef>Total Lines</th>
                        <td mat-cell *matCellDef="let element">{{element.TOTAL_LINES}}</td>
                    </ng-container>

                    <ng-container matColumnDef="PICKERS">
                        <th mat-header-cell *matHeaderCellDef>Users</th>
                        <td mat-cell *matCellDef="let element">{{element.PICKERS}}</td>
                    </ng-container>

                    <ng-container matColumnDef="LPH">
                        <th mat-header-cell *matHeaderCellDef>TTL LPH</th>
                        <td mat-cell *matCellDef="let element">{{element.LPH}}</td>
                    </ng-container>

                    <ng-container matColumnDef="PERFORMANCE">
                        <th mat-header-cell *matHeaderCellDef>TTL Performance</th>
                        <td mat-cell *matCellDef="let element">{{element.PERFORMANCE | percent}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>

                <mat-card>Total Pieces: {{summedPieces}}</mat-card>
                <mat-card>Total Lines: {{summedLines}}</mat-card>
                <mat-card>Average LPH: {{averageLPH | number:'1.0-0'}}</mat-card>
                <mat-card>Average Performance: {{averagePerformance | percent}}</mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab label="Graph">
            <ng-template matTabContent>
                <div class="graph-positioning">
                    <fusioncharts width="1000" height="600" type="line" [dataSource]="dataSource">
                    </fusioncharts>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #mobileView>
    <mat-tab-group>
        <mat-tab label="Table">
            <mat-card class="hourCard" *ngFor="let element of cardViewData">
                <mat-card-header>
                    <mat-card-title>Hour: {{element.HOUR}}</mat-card-title>
                </mat-card-header>
                <mat-card-content class="fx-row-space-evenly-center">
                    <div>
                        <h5>Total Lines</h5>
                        <h3 class="center-element">{{element.TOTAL_LINES}}</h3>
                    </div>
                    <div>
                        <h5>Total PCS</h5>
                        <h3 class="center-element">{{element.TOTAL_PIECES}}</h3>
                    </div>
                    <div>
                        <h5>TTL LPH</h5>
                        <h3 class="center-element">{{element.LPH}}</h3>
                    </div>
                    <div>
                        <h5>TTL Performance</h5>
                        <h3 class="center-element">{{element.PERFORMANCE | percent}}</h3>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card>Total Pieces: {{summedPieces}}</mat-card>
            <mat-card>Total Lines: {{summedLines}}</mat-card>
            <mat-card>Average LPH: {{averageLPH | number:'1.0-0'}}</mat-card>
            <mat-card>Average Performance: {{averagePerformance | percent}}</mat-card>
        </mat-tab>
        <mat-tab label="Graph">
            <ng-template matTabContent>
                <div class="graph-positioning">
                    <fusioncharts width="100%" height="550" type="line" [dataSource]="dataSource">
                    </fusioncharts>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</ng-template>