<div *ngIf="(isScreen$ | async)?.matches"> 
  <table mat-table [dataSource]="dataSource" matSort matSortActive="totalOrders"
      matSortDirection="asc" matSortStart="desc" matSortDisableClear>
    <ng-container matColumnDef="buildLocation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pack Method</th>
      <td mat-cell *matCellDef="let d">{{d.buildLocation}}</td>
    </ng-container>
    <ng-container matColumnDef="minProcess">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Oldest In Process</th>
      <td mat-cell *matCellDef="let d">{{d.minProcess ? (d.minProcess | amFromUtc | amDateFormat: 'YYYY-MM-DD' ) : 'None'}}</td>
    </ng-container>
    <ng-container matColumnDef="minScheduled">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Oldest Scheduled</th>
      <td mat-cell *matCellDef="let d">{{d.minScheduled ? (d.minScheduled | amFromUtc | amDateFormat: 'YYYY-MM-DD' ) : 'None'}}</td>
    </ng-container>
    <ng-container matColumnDef="totalOrders">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Work Orders</th>
      <td mat-cell *matCellDef="let d">{{d.totalOrders | number:'1.0-0'}}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns; sticky:true"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  </table>
</div>
<div *ngIf="(isTablet$ | async)?.matches"  class="card-list">
  <div *ngFor="let d of mDataSource"> 
    <mat-card class="cards cards card-details" (click)="prepackDetail(d.key)">
      <mat-card-header>
        <mat-card-title>{{ d.buildLocation }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list>
            <mat-list-item>Oldest In Process: {{ d.minProcess ? (d.minProcess | amFromUtc | amDateFormat: 'YYYY-MM-DD' ) : 'None'}}</mat-list-item>
            <mat-list-item>Oldest Scheduled: {{ d.minScheduled ? (d.minScheduled | amFromUtc | amDateFormat: 'YYYY-MM-DD' ) : 'None'}}</mat-list-item>
            <mat-list-item>Total Work Orders: {{ d.totalOrders | number:'1.0-0'}}</mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>
