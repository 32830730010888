import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SummaryByWorkerService, WorkerStats } from '../services/summary-by-worker.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { Subscription} from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { hourModel } from './worker-pack-model'
import { WorkpackmetricdetailService } from '../services/workpackmetricdetail.service';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { DatePickerVisibilityService } from '../services/date-picker-visibility.service';
import { DateFilter } from '../models/partsWarehouse';
import { DateRangeSelectorService } from '../services/date-range-selector.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-worker-pack-metrics',
  templateUrl: './worker-pack-metrics.component.html',
  styleUrls: ['./worker-pack-metrics.component.scss'],
})
export class WorkerPackMetricsComponent implements OnInit, OnDestroy {
  d: Subscription;
  hours: hourModel[] = [
    {value: -2, viewValue: 'All Day'},
    {value: -1, viewValue: 'Current Hour'}
  ];
  public hourlyViewFormGroup = new UntypedFormGroup({
    hourControl: new UntypedFormControl()
  })
  hourObj: hourModel;
  timeToRefreshHourArray: number;
  hourlyView: boolean;
  timeFrame: any;
  todayDate: string
  selectedHour: number;
  dataSource:MatTableDataSource<WorkerStats>;
  columns: string[]=["Name", "Orders", "LABELS", "labelsPerShipment", "Pieces", "piecesPerOrder"];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  siteId: string;
  totalsObj: object = {};

  constructor(
    private service: SummaryByWorkerService,
    private route: ActivatedRoute,
    private hourlyService: WorkpackmetricdetailService,
    private datePickVis: DatePickerVisibilityService,
    private dateRangeSelectorService: DateRangeSelectorService,
    private router: Router
  ) {
    dayjs.extend(timezone);
    dayjs.extend(utc);
    this.todayDate = dayjs().format('YYYY MM DD');
    this.hourlyViewFormGroup.controls['hourControl'].setValue(-2);
    this.timeToRefreshHourArray = 360000000
  }

  ngOnInit() {
    this.datePickVis.updateDatePickerVis(false)
    this.dataSource = new MatTableDataSource<WorkerStats>();
    this.siteId = this.route.snapshot.paramMap.get('siteId'); 
    this.fetch();
    this.populateHourDropDown();
    setInterval(this.populateHourDropDown, this.timeToRefreshHourArray)

    this.hourlyViewFormGroup.valueChanges.subscribe( val => {
      this.fetch(val["hourControl"])
    })
    this.datePickVis.updateDatePickerVis(true)
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
}

  populateHourDropDown(){
    let now = dayjs().hour();
    let hour = 0
    let minNow = dayjs().minute();

    this.timeToRefreshHourArray = (((60 - minNow) * (60)) * 1000)

    this.hours.length = 0

    this.hours.push({value: -2, viewValue: 'All Day'})
    this.hours.push({value: -1, viewValue: 'Current Hour'})

    for (hour = 0; hour <= now; hour++){
      let dateVal = (dayjs().hour(hour)).tz('America/New_York');
      let dateViewVal = dayjs().hour(hour);

      this.hourObj = {
        value: dateVal.hour(),
        viewValue: dateViewVal.startOf('hour').format('h:mm A')
      }

      this.hours.push(this.hourObj)
    }
  }

  timeTransformer(time: number): number{
    switch(time) {
      case(-1): return dayjs().tz('America/New_York').startOf('hour').hour();
      case(-2): return null;
      default: return time;
    }
  }

  fetch(hour?: number) {
    if (this.d){
      this.d.unsubscribe()
    }
    let timeFrame = this.timeTransformer(hour)

    this.d =
      this.dateRangeSelectorService.dateRangeSelected$.pipe(
        tap(df=>console.log("invoke worker pack metrics",df)),
        switchMap((df: DateFilter) =>
          this.service.sumByWorker(this.siteId, df, timeFrame)
        )
      )
      .subscribe((next) => {
        this.totalsObj = next.parts.find(obj => {return obj.Name === "TOTAL"})
        this.dataSource.data=next.parts;
        this.dataSource.data.shift();
        this.dataSource.sort=this.sort;
      })
  }

  ngOnDestroy() {
    this.d && this.d.unsubscribe();
  }

  shipmentDetails(sso: string) {
    this.router.navigate(['/workerdetail', this.siteId, sso]);
  }

}
