<div #partsSOS class="container">
  <h2> Speed of Shipment </h2>
  <mat-card *ngFor="let d of headerData$ | async; trackBy: trackByIndex">
    <mat-expansion-panel>
      <mat-expansion-panel-header [collapsedHeight]="'190px'" [expandedHeight]="'190px'">
        <mat-card-title-group class="title">
          <mat-card-title>
            <div class="container">
              <table>
                <tr>
                  <th>Planned Ship Date</th>
                  <th>Total Lines</th>
                  <th>Lines Late</th>
                  <th>Lines Ontime</th>
                  <th>SOS %</th>
                </tr>
                <tr>
                  <th>{{d["planned_ship_date"]}}</th>
                  <th>{{d["Total Lines"]}}</th>
                  <th>{{d["Lines Late"]}}</th>
                  <th>{{d["Lines Ontime"]}}</th>
                  <th>{{d["SOS"] | number:'1.2-2'}}</th>
                </tr>
              </table>
            </div>
          </mat-card-title>
        </mat-card-title-group>
      </mat-expansion-panel-header>
      <mat-card-content>
        <table mat-table [dataSource]="d.DETAILS" matSortdisabled>
          <ng-container matColumnDef="PRIORITY">
              <th mat-header-cell *matHeaderCellDef>Priority</th>
              <td 
                mat-cell 
                *matCellDef="let d" 
                (click)="salesOrders('Jeffersonville Warehouse', d.PLANNED_SHIP_DATE, '', d.PRIORITY)">
                {{d.PRIORITY}}
              </td>
          </ng-container>
          <ng-container matColumnDef="Lines">
              <th mat-header-cell *matHeaderCellDef>Lines</th>
              <td mat-cell *matCellDef="let d">{{d.Lines}}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns:columns;"></tr>
        </table>
      </mat-card-content>
    </mat-expansion-panel>
  </mat-card>
</div>
