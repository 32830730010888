import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ProductivityService } from '../productivity.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-date-select',
  templateUrl: './date-select.component.html',
  styleUrls: ['./date-select.component.scss']
})
export class DateSelectComponent implements OnInit {

  objectid:string;
  site:string;

  shiftFormGroup:UntypedFormGroup;
  shiftList:string[] = ['1ST SHIFT','2ND SHIFT','3RD SHIFT'];

  shiftdate:string;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private fb: UntypedFormBuilder,
    private prodservice:ProductivityService,
    @Inject(MAT_DIALOG_DATA) public inData: any
  ) { 
    this.objectid = this.inData.objectid;
    this.site = this.inData.site;

    this.shiftFormGroup = this.fb.group({
      Shift: new UntypedFormControl({value: "1ST SHIFT", disabled: false}, Validators.required)
    });

  }

  ngOnInit(): void {
  }

  onDateSelection(e) {
    this.shiftdate = dayjs(e.start).format('YYYY-MM-DD'); 
    console.log("New shiftdate", this.shiftdate);
  }

  changeShift():void {
    console.log("Change Shift", this.shiftdate);

    let shift:string = this.shiftFormGroup.get('Shift').value;
    let startDate:string = '';
    let endDate:string = '';

    if (shift == '1ST SHIFT') {
      startDate = this.shiftdate + " 06:00:00";  
      endDate = this.shiftdate + " 15:00:00";  
    } else if (shift == '2ND SHIFT') {
      startDate = this.shiftdate + " 15:00:00";  
      endDate = this.shiftdate + " 22:00:00";  
    } else if (shift == '3RD SHIFT') {
      startDate = this.shiftdate + " 22:00:00";
      endDate = this.shiftdate + " 06:00:00";
    }

    this.prodservice.changeDate(startDate, endDate, this.shiftdate, shift);

    this.dialogRef.close();
  }

  onNoClick():void {
    console.log("Close");
    this.dialogRef.close();
  }

}

