import { Component, OnInit, Input } from '@angular/core';
import { SumByTransportMode } from '../services/summary-by-site.service';

@Component({
  selector: 'app-export-mode-summary',
  templateUrl: './export-mode-summary.component.html',
  styleUrls: ['./export-mode-summary.component.scss']
})
export class ExportModeSummaryComponent implements OnInit {

  @Input() metrics:Partial<SumByTransportMode>;

  constructor() { }

  ngOnInit() {
  }

}
