<div class="table-container">
      
      <h1>User Productivity Report</h1>
      <!--<div><button mat-button (click)="changeShiftDate()"><mat-icon>calendar_today</mat-icon></button> {{shiftDate}} - {{shift}}</div>-->
      <form [formGroup]="shiftFormGroup">
            <div class="fx-row-start-center">
                  <div>
                        <gea-mes-datepicker
                        class="dateRangeBut"
                        matTooltip="Select Date" 
                        (onDateSelect)="onDateSelection($event)" 
                        (onDateInit)="onDateSelection($event)"
                        singleDate="true" 
                        showLabel="true"
                        showCurrentShift="false"
                        defaultRange="Today"
                        showRangeLabel="true"
                        autoApply="true"
                        index="2">
                        </gea-mes-datepicker>
                  </div>
                  <div>
                        <div class="fx-row-space-between-center">
                              <div style="margin-right: 10px">Shift:</div>
                              <mat-form-field style="margin-right: 10px" [style.width.px]="130">
                              <mat-select formControlName="Shift" (ngModelChange)="onShiftSelection($event)">
                                    <mat-option *ngFor="let d of shiftList" [value]="d">{{d}}</mat-option>
                              </mat-select>
                              </mat-form-field>
                        </div>
                  </div>
            </div>
      </form>
      <table mat-table [dataSource]="datasource">
            <ng-container matColumnDef="username" sticky>
                  <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2" class="align-header">User Name</th>
                  <td mat-cell *matCellDef="let d" (click)="navToUserHourSummary(d.SSO)">{{d.PICKER}}</td>
            </ng-container>

            <!-- Single Pick Columns -->

            <ng-container matColumnDef="single_lines">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Lines</th>
                  <td mat-cell *matCellDef="let d">{{d.SINGLE_TOTAL_LINES}}</td>
            </ng-container>

            <ng-container matColumnDef="single_pieces">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Pieces</th>
                  <td mat-cell *matCellDef="let d">{{d.SINGLE_TOTAL_PIECES}}</td>
            </ng-container>

            <ng-container matColumnDef="single_hours" class="align-header">
                  <th mat-header-cell *matHeaderCellDef>Hours Worked</th>
                  <td mat-cell *matCellDef="let d">{{d.SINGLE_HOURS_WORKED}}</td>
            </ng-container>

            <ng-container matColumnDef="single_lph">
                  <th mat-header-cell *matHeaderCellDef class="align-header">LPH</th>
                  <td mat-cell *matCellDef="let d">{{d.SINGLE_LPH}}</td>
            </ng-container>

            <ng-container matColumnDef="single_performance">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Performance</th>
                  <td mat-cell *matCellDef="let d">{{d.SINGLE_PERFORMANCE | percent}}</td>
            </ng-container>

            <!-- Multi Pick Columns -->

            <ng-container matColumnDef="multi_lines">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Lines</th>
                  <td mat-cell *matCellDef="let d">{{d.MULTI_TOTAL_LINES}}</td>
            </ng-container>
            
            <ng-container matColumnDef="multi_pieces">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Pieces</th>
                  <td mat-cell *matCellDef="let d">{{d.MULTI_TOTAL_PIECES}}</td>
            </ng-container>
            
            <ng-container matColumnDef="multi_hours">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Hours Worked</th>
                  <td mat-cell *matCellDef="let d">{{d.MULTI_HOURS_WORKED}}</td>
            </ng-container>
            
            <ng-container matColumnDef="multi_lph">
                  <th mat-header-cell *matHeaderCellDef class="align-header">LPH</th>
                  <td mat-cell *matCellDef="let d">{{d.MULTI_LPH}}</td>
            </ng-container>
            
            <ng-container matColumnDef="multi_performance">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Performance</th>
                  <td mat-cell *matCellDef="let d">{{d.MULTI_PERFORMANCE | percent}}</td>
            </ng-container>

            <!-- LTL Pick Columns -->

            <ng-container matColumnDef="ltl_lines">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Lines</th>
                  <td mat-cell *matCellDef="let d">{{d.LTL_TOTAL_LINES}}</td>
            </ng-container>
            
            <ng-container matColumnDef="ltl_pieces">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Pieces</th>
                  <td mat-cell *matCellDef="let d">{{d.LTL_TOTAL_PIECES}}</td>
            </ng-container>
            
            <ng-container matColumnDef="ltl_hours">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Hours Worked</th>
                  <td mat-cell *matCellDef="let d">{{d.LTL_HOURS_WORKED}}</td>
            </ng-container>
            
            <ng-container matColumnDef="ltl_lph">
                  <th mat-header-cell *matHeaderCellDef class="align-header">LPH</th>
                  <td mat-cell *matCellDef="let d">{{d.LTL_LPH}}</td>
            </ng-container>
            
            <ng-container matColumnDef="ltl_performance">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Performance</th>
                  <td mat-cell *matCellDef="let d">{{d.LTL_PERFORMANCE | percent}}</td>
            </ng-container>

            <!-- Total Pick Columns -->

            <ng-container matColumnDef="total_lines">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Lines</th>
                  <td mat-cell *matCellDef="let d">{{d.TOTAL_LINES}}</td>
            </ng-container>

            <ng-container matColumnDef="total_pieces">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Pieces</th>
                  <td mat-cell *matCellDef="let d">{{d.TOTAL_PIECES}}</td>
            </ng-container>

            <ng-container matColumnDef="total_hours">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Hours Worked</th>
                  <td mat-cell *matCellDef="let d">{{d.TOTAL_HOURS_WORKED}}</td>
            </ng-container>

            <ng-container matColumnDef="total_lph">
                  <th mat-header-cell *matHeaderCellDef class="align-header">LPH</th>
                  <td mat-cell *matCellDef="let d">{{d.TOTAL_LPH}}</td>
            </ng-container>

            <ng-container matColumnDef="total_performance">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Performance</th>
                  <td mat-cell *matCellDef="let d">{{d.TOTAL_PERFORMANCE | percent}}</td>
            </ng-container>

            <!-- Section Header -->

            <ng-container matColumnDef="single_header">
                  <th mat-header-cell *matHeaderCellDef [attr.colspan]="5" style="text-align: center">Single Picks</th> 
            </ng-container>

            <ng-container matColumnDef="multi_header">
                  <th mat-header-cell *matHeaderCellDef  [attr.colspan]="5" style="text-align: center">Multi Picks</th> 
            </ng-container>

            <ng-container matColumnDef="ltl_header">
                  <th mat-header-cell *matHeaderCellDef  [attr.colspan]="5" style="text-align: center">LTL Picks</th> 
            </ng-container>

            <ng-container matColumnDef="total_header">
                  <th mat-header-cell *matHeaderCellDef  [attr.colspan]="5" style="text-align: center">Total</th> 
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displaySectionColumns; sticky: true;"></tr>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
            <tr mat-row *matRowDef="let row; columns: dataColumns;"></tr>
      </table>
</div>