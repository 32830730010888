import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericLambdaReturn,  DateFilter } from '../models/partsWarehouse';
import { PartsServiceUtil } from './parts.service.util';
import { AbstractRestService } from './abstract.rest.service'

@Injectable({
  providedIn: 'root'
})
export class SpeedOfShipmentService extends AbstractRestService {

  getSOSData(siteId: string):Observable<GenericLambdaReturn<sosType[]>> {
    return this.get('/sos', PartsServiceUtil.buildParams(siteId, null, null, null, null));
  }
}

export type sosType = {
  planned_ship_date: string;
  "Total Lines": number;
  "Lines Late": number;
  "Lines Ontime": number;
  SOS: number;
}

export type sosDetailType = {
  PLANNED_SHIP_DATE: string;
  PRIORITY: number;
  Lines: number;
}