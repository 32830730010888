import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericLambdaReturn, DateFilter } from '../models/partsWarehouse';
import { PartsServiceUtil } from './parts.service.util';
import { AbstractRestService } from './abstract.rest.service'

@Injectable({
  providedIn: 'root'
})
export class WorkerShipmentDetail extends AbstractRestService {

  shipmentDetail(siteId: string, df: DateFilter, hour: number, sso: string):Observable<GenericLambdaReturn<ShipmentDetails[]>> {
    return this.get('/packlabeldetail', PartsServiceUtil.buildParams(siteId,df,null, null, hour, null, sso));
  }
}

export type orders = {
  ERP_ORDER: string;
  CUST_CLASS: string;
  Item: string,
  VOLUME: number,
  WEIGHT: number,
  ITEM_HEIGHT: number,
  ITEM_WIDTH: number,
  ITEM_LENGTH: number
}

export type ShipmentDetails = {
  SHIPMENT_ID: string;
  TOTAL_VOLUME: number;
  TOTAL_WEIGHT: number;
  ITEMS: orders[]
}