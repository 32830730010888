import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DateSelectComponent } from '../date-select/date-select.component';
import { ProductivityService } from '../productivity.service';

@Component({
  selector: 'app-summary-hour',
  templateUrl: './summary-hour.component.html',
  styleUrls: ['./summary-hour.component.scss']
})
export class SummaryHourComponent implements OnInit {
  
  datasource:any[];

  subSummary$:Subscription;
  sso:string;

  displayedColumns: string[] = [ 'single_lines', 'single_pieces', 'single_orders', 'single_performance', 'multi_lines', 'multi_pieces', 'multi_orders', 'multi_performance', 'ltl_lines', 'ltl_pieces', 'ltl_orders', 'ltl_performance', 'total_lines', 'total_pieces', 'total_orders', 'total_performance' ];
  dataColumns: string[] =  [ 'hour', 'username', 'single_lines', 'single_pieces', 'single_orders', 'single_performance', 'multi_lines', 'multi_pieces', 'multi_orders', 'multi_performance', 'ltl_lines', 'ltl_pieces', 'ltl_orders', 'ltl_performance', 'total_lines', 'total_pieces', 'total_orders', 'total_performance'];
  displaySectionColumns: string[] = ['hour', 'username', 'single_header','multi_header','ltl_header', 'total_header'];
  
  shiftDate:string;
  shift:string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private prodservice:ProductivityService
  ) { }

  ngOnInit(): void {
    this.sso = this.route.snapshot.paramMap.get('sso');
    this.getData();
  }

  ngOnDestroy() {
    if (this.subSummary$) this.subSummary$.unsubscribe();
  }  

  getData():void {
    if (this.subSummary$) this.subSummary$.unsubscribe();

    this.subSummary$ = this.prodservice.getUserHourSummary(this.sso).subscribe(
      (out) => {
        console.log("Retrieved user hourly summary!",out);
        this.datasource = out.Body;
        this.shiftDate = this.prodservice.shiftDate;
        this.shift = this.prodservice.shift;
      }
    )
  }

  changeShiftDate() {
    console.log("changeShiftDate");

    const dialogRef = this.dialog.open(DateSelectComponent, {
      width: '300px',
      maxHeight: '800px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Close ShiftSelectDialogComponent");
      this.getData();
    });
  }

  navToUserDetail(sso:string, hour:string) {
    this.router.navigate(['/userproductivity/detail', sso, hour]);
  }

  navToSummary() {
    this.router.navigate(['/userproductivity']);
  }
}
