import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericLambdaReturn,  DateFilter } from '../models/partsWarehouse';
import { PartsServiceUtil } from './parts.service.util';
import { AbstractRestService } from './abstract.rest.service'

@Injectable({
  providedIn: 'root'
})
export class OrderChaseService extends AbstractRestService{

  orderChase(siteId: string, df: DateFilter):Observable<GenericLambdaReturn<OrderChase[]>> {
    return this.get('/partsshipping', PartsServiceUtil.buildParams(siteId,df, null, null, null));
  }
}

export type OrderChase = {
  PLANNED: string;
  DESCRIPTION: string;
  Z00Pieces: number;
  ZOTPieces: number;
  PDPieces: number;
  AWPieces: number;
  RFPieces: number;
  EXPieces: number;
  NCPieces: number;
  OtherPieces: number;
  Z00Lines: number;
  ZOTLines: number;
  PDLines: number;
  AWLines: number;
  RFLines: number;
  EXLines: number;
  NCLines: number;
  OtherLines: number;
}
