import { Injectable } from '@angular/core';
import { GenericLambdaReturn, SalesOrderParams } from '../models/partsWarehouse';
import { PartsServiceUtil } from './parts.service.util';
import { AbstractRestService } from './abstract.rest.service'
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SalesOrdersService extends AbstractRestService{

  displaySalesOrders(siteId: string, custClass: string, plannedDate: string, priority:string):Observable<GenericLambdaReturn<SalesOrders[]>> {
    console.log('displaySalesOrdrers function called: ')
    console.log('siteId: ', siteId)
    return this.get('/partssalesorders', PartsServiceUtil.buildParams(siteId, null, custClass, plannedDate, null, null, null, priority));
  }
}

export type SalesOrders = {
  CREATION_DATE_TIME_STAMP: string;
  PLANNED_SHIP_DATE: string;
  PRIORITY: number;
  ORDER_TYPE: string;
  SHIPMENT_ID: string;
  LINES: number;
  CARRIER: string;
  CUSTOMER_CATEGORY7: string;
  LEADING_STS: number;
  TRAILING_STS: number;
  DESCRIPTION: string;
}
