import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { GenericLambdaReturn } from '../models/partsWarehouse';
import { Observable, timer } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Injectable()
export class AbstractRestService {
  env:any = environment;
  protected baseUrl: string;
  refreshTime: number =30000; //refresh time

  constructor(private http:HttpClient) {
    this.baseUrl = this.env.apigwUrl;
  }

  get(relativeUrl: string, params: HttpParams): Observable<GenericLambdaReturn<any>>
  {
    let p:HttpParams = params.append('t', `${Date.now()}`);
    let req = this.http.get<GenericLambdaReturn<any>>(this.baseUrl+relativeUrl, {params: p});
    return timer(0,this.refreshTime).pipe(concatMap(_=>req));
  }
}
