import { Component, OnInit, ViewChild} from '@angular/core';
import { PickMetricUserService, PickMetric } from '../services/pick-metric-user.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { Observable, Subscription } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { hourModel } from '../worker-pack-detail/worker-pack-model';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';



@Component({
  selector: 'app-pick-metric-user',
  templateUrl: './pick-metric-user.component.html',
  styleUrls: ['./pick-metric-user.component.scss']
})


export class PickMetricUserComponent implements OnInit {
  displayedColumns: string[] = ["Picker", "TOTAL_LINES", "TOTAL_PIECES", "Hours_Worked", "LPH", "PERFORMANCE"];
  dataSource: MatTableDataSource<PickMetric>;
  @ViewChild(MatSort) set content(sort: MatSort){
    this.dataSource.sort = sort;
  };
  PickMetricUserObservable: Observable<any>
  PickMetricUserSubscription: Subscription
  hours: hourModel[] = [
    {value: null, viewValue: 'All Day'},
    {value: -1, viewValue: 'Current Hour'}
  ];
  hourObj: hourModel;
  timeToRefreshHourArray: number;
  isMobile : boolean;
  cardViewData : PickMetric[]
  
  public hourlyViewFormGroup = new UntypedFormGroup({
    hourControl: new UntypedFormControl()
  })

  constructor(private PickMetricUserService: PickMetricUserService,
              private breakpointObserver: BreakpointObserver) {

    dayjs.extend(timezone)
    dayjs.extend(utc)

    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((result) => {
      if (result.matches) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    })
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<PickMetric>()
    this.getPickMetricUserTableData()
    this.populateHourDropDown()

    this.hourlyViewFormGroup.valueChanges.subscribe( val => {
      this.getPickMetricUserTableData(val["hourControl"])
    })

  }

  getPickMetricUserTableData(Hour? : number) : void {
    if (this.PickMetricUserSubscription) this.PickMetricUserSubscription.unsubscribe()
    this.PickMetricUserObservable = this.PickMetricUserService.getPickMetricUserData(Hour).pipe(
      pluck("Body")
    )
    
    this.PickMetricUserSubscription = this.PickMetricUserObservable.subscribe((tableData) => {
      let tempTableData: PickMetric[]
      tempTableData = tableData
      this.dataSource = new MatTableDataSource(tempTableData);
      this.cardViewData = tableData
      console.log(tableData)
    })
  }

  populateHourDropDown(){
    let now = dayjs().hour();
    let hour = 0
    let minNow = dayjs().minute();
  
    this.timeToRefreshHourArray = (((60 - minNow) * (60)) * 1000)
  
    this.hours.length = 0
  
    this.hours.push({value: null, viewValue: 'All Day'})
    this.hours.push({value: dayjs().hour(), viewValue: 'Current Hour'})
  
    for (hour = 0; hour <= now; hour++){
      let dateVal = (dayjs().hour(hour)).tz('America/New_York');
      let dateViewVal = dayjs().hour(hour);
  
      this.hourObj = {
        value: dateVal.hour(),
        viewValue: dateViewVal.startOf('hour').format('h:mm A')
      }
  
      this.hours.push(this.hourObj)
    }
  }

  ngOnDestroy() {
    this.PickMetricUserSubscription.unsubscribe();
  }

}
