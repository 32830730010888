<div class="table-container">
      <h1>User Productivity Report - Transaction Details</h1>
      <div><button mat-button (click)="navToUserHourSummary()">Back to Hourly Summary</button> <button mat-button (click)="changeShiftDate()"><mat-icon>calendar_today</mat-icon></button> {{shiftDate}} - {{shift}}</div>
      <table mat-table [dataSource]="datasource">
            <ng-container matColumnDef="username" sticky>
                  <th mat-header-cell *matHeaderCellDef class="align-header">User Name</th>
                  <td mat-cell *matCellDef="let d">{{d.PICKER}}</td>
            </ng-container>

            <ng-container matColumnDef="activity_date_time">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Date Time</th>
                  <td mat-cell *matCellDef="let d">{{d.ACTIVITY_DATE_TIME | date:'YYYY-MM-dd HH:mm:ss'}}</td>
            </ng-container>

            <ng-container matColumnDef="lines">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Lines</th>
                  <td mat-cell *matCellDef="let d">{{d.LINES}}</td>
            </ng-container>

            <ng-container matColumnDef="pieces">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Pieces</th>
                  <td mat-cell *matCellDef="let d">{{d.PIECES}}</td>
            </ng-container>  

            <ng-container matColumnDef="item">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Item</th>
                  <td mat-cell *matCellDef="let d">{{d.ITEM }}</td>
            </ng-container>

            <ng-container matColumnDef="shipment_id">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Shipment ID</th>
                  <td mat-cell *matCellDef="let d">{{d.SHIPMENT_ID}}</td>
            </ng-container>
            
            <ng-container matColumnDef="activity_type">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Activity Type</th>
                  <td mat-cell *matCellDef="let d">{{d.ACTIVITY_TYPE}}</td>
            </ng-container>

            <ng-container matColumnDef="work_group">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Work Group</th>
                  <td mat-cell *matCellDef="let d">{{d.WORK_GROUP}}</td>
            </ng-container>            
            
            <ng-container matColumnDef="total_qty">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Total Qty</th>
                  <td mat-cell *matCellDef="let d">{{d.TOTAL_QTY}}</td>
            </ng-container>

            <ng-container matColumnDef="qty_um">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Qty UM</th>
                  <td mat-cell *matCellDef="let d">{{d.QUANTITY_UM}}</td>
            </ng-container>
            
            <ng-container matColumnDef="device_type">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Device Type</th>
                  <td mat-cell *matCellDef="let d">{{d.DEVICE_TYPE}}</td>
            </ng-container>

            <ng-container matColumnDef="container_id">
                  <th mat-header-cell *matHeaderCellDef class="align-header">Container ID</th>
                  <td mat-cell *matCellDef="let d">{{d.CONTAINER_ID}}</td>
            </ng-container>              
            
            <ng-container matColumnDef="license_plate">
                  <th mat-header-cell *matHeaderCellDef class="align-header">License Plate</th>
                  <td mat-cell *matCellDef="let d">{{d.LICENSE_PLATE}}</td>
            </ng-container>  

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
</div>