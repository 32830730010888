import { Component, OnInit } from '@angular/core';
import { SpeedOfShipmentService, sosType} from '../services/speed-of-shipment.service'
import { DateFilter} from '../models/partsWarehouse';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { DatePickerVisibilityService } from '../services/date-picker-visibility.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-speed-of-shipment',
  templateUrl: './speed-of-shipment.component.html',
  styleUrls: ['./speed-of-shipment.component.scss']
})
export class SpeedOfShipmentComponent implements OnInit {

  sosData: sosType[]
  headerData$: Observable<sosType[]>;
  dateFilter: DateFilter
  trackByIndex = index => index;
  viewSelection: boolean = false;
  color: any;
  checked: boolean;
  disabled: boolean;
  subscription: Subscription;

  columns: string[]=["PRIORITY","Lines"];

  onChange(value) {
    this.viewSelection = value.checked
  }
  constructor(
    private service: SpeedOfShipmentService,
    private route: ActivatedRoute,
    private router: Router,
    private datePickVis: DatePickerVisibilityService
  ) {}

  ngOnInit() {
    this.datePickVis.updateDatePickerVis(false)
    this.headerData$ =
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          this.service.getSOSData(params.get('siteId'))
        ),
        map(r=>r.parts)
      );
  }

  salesOrders(siteId: string, plannedDate: string, custClass: string, priority:number){
    this.router.navigate(['/salesorders', siteId, plannedDate, custClass, priority])
  }

}
