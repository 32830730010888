<div #orderChase class="container">
  <h2> Order Chase </h2>
  <mat-card class="result">
    <mat-card-content>
      <section class="slider-section">
        <span [ngClass]="{'selected': viewSelection == false, 
                        'not-selected': viewSelection == true}"
        class="select-label-lines">Lines</span>
        <mat-slide-toggle
            class="slider-margin"
            [color]="color"
            [checked]="checked"
            [disabled]="disabled"
            (change)="onChange($event)">
          <span [ngClass]="{'selected' : viewSelection == true,
                           'not-selected': viewSelection == false}"
          class="select-label-pieces">Pieces</span>
        </mat-slide-toggle>
      </section>
    </mat-card-content>
  </mat-card>

  <mat-card *ngFor="let d of data$ | async; trackBy: trackByIndex">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-card-title-group>
          <mat-card-title> {{d.PLANNED}}
            <button mat-button (click)="salesOrders(d.DESCRIPTION,d.PLANNED,'','')">
              All Orders
            </button>
          </mat-card-title>
        </mat-card-title-group>
      </mat-expansion-panel-header>
        <mat-card-content>
          <div *ngIf="!viewSelection; else Pieces">
            <div class="container" >
                <mat-list class="card-list">
                    <div>
                      <mat-list-item *ngIf="d.Z00Lines >0"
                        #tooltip="matTooltip"
                        (mouseenter)="tooltip.show()"
                        matTooltip="GEA Tech - Single Shipment"
                        matTooltipPosition="above"
                        (click)="salesOrders(d.DESCRIPTION,d.PLANNED,'ZOO','')">
                        ZOO : {{d.Z00Lines | number:'1.0-0'}}
                      </mat-list-item>
                    </div>
                    <div>
                      <mat-list-item *ngIf="d.ZOTLines >0"
                          #tooltip="matTooltip"
                          (mouseenter)="tooltip.show()"
                          matTooltip= "GEA Tech - Unlimited Shipment"
                          matTooltipPosition="above"
                          (click)="salesOrders(d.DESCRIPTION,d.PLANNED,'ZOT','')">
                          ZOT : {{d.ZOTLines | number:'1.0-0'}}
                      </mat-list-item>
                    </div>
                    <div>
                      <mat-list-item *ngIf="d.PDLines >0"
                          #tooltip="matTooltip"
                          (mouseenter)="tooltip.show()"
                          matTooltip= "Parts Distributor"
                          matTooltipPosition="above"
                          (click)="salesOrders(d.DESCRIPTION,d.PLANNED,'PD','')">
                          PD : {{d.PDLines | number:'1.0-0'}}
                      </mat-list-item>
                    </div>
                    <div>
                      <mat-list-item *ngIf="d.AWLines >0"
                          #tooltip="matTooltip"
                          (mouseenter)="tooltip.show()"
                          matTooltip= "TBD"
                          matTooltipPosition="above"
                          (click)="salesOrders(d.DESCRIPTION,d.PLANNED,'AW','')">
                          AW : {{d.AWLines | number:'1.0-0'}}
                      </mat-list-item>
                    </div>
                    <div>
                      <mat-list-item *ngIf="d.RFLines >0"
                          #tooltip="matTooltip"
                          (mouseenter)="tooltip.show()"
                          matTooltip= "Consumer"
                          matTooltipPosition="above"
                          (click)="salesOrders(d.DESCRIPTION,d.PLANNED,'RF','')">
                          RF : {{d.RFLines | number:'1.0-0'}}
                      </mat-list-item>
                    </div>
                    <div>
                      <mat-list-item *ngIf="d.EXLines >0"
                          #tooltip="matTooltip"
                          (mouseenter)="tooltip.show()"
                          matTooltip= "Export"
                          matTooltipPosition="above"
                          (click)="salesOrders(d.DESCRIPTION,d.PLANNED,'EX','')">
                          EX : {{d.EXLines | number:'1.0-0'}}
                      </mat-list-item>
                    </div>
                    <div>
                      <mat-list-item *ngIf="d.NCLines >0"
                          #tooltip="matTooltip"
                          (mouseenter)="tooltip.show()"
                          matTooltip="LDC"
                          matTooltipPosition="above"
                          (click)="salesOrders(d.DESCRIPTION,d.PLANNED,'NC','')">
                          NC : {{d.NCLines | number:'1.0-0'}}
                      </mat-list-item>
                    </div>
                    <div>
                      <mat-list-item *ngIf="d.OTHERLines >0">
                        Other : {{d.OTHERLines | number:'1.0-0'}}
                      </mat-list-item>
                    </div>
                </mat-list>
            </div>
          </div>
          <ng-template #Pieces>
            <div class="container">
              <mat-list class="card-list">
                <div>
                  <mat-list-item *ngIf="d.Z00Pieces >0"
                    #tooltip="matTooltip"
                    (mouseenter)="tooltip.show()"
                    matTooltip="GEA Tech - Single Shipment"
                    matTooltipPosition="above"
                    (click)="salesOrders(d.DESCRIPTION,d.PLANNED,'ZOO','')">
                    ZOO : {{d.Z00Pieces | number:'1.0-0'}}
                  </mat-list-item>
                </div>
                <div>
                  <mat-list-item *ngIf="d.ZOTPieces >0"
                    #tooltip="matTooltip"
                    (mouseenter)="tooltip.show()"
                    matTooltip= "GEA Tech - Unlimited Shipment"
                    matTooltipPosition="above"
                    (click)="salesOrders(d.DESCRIPTION,d.PLANNED,'ZOT','')">
                    ZOT : {{d.ZOTPieces | number:'1.0-0'}}
                  </mat-list-item>
                </div>
                <div>
                  <mat-list-item *ngIf="d.PDPieces >0"
                      #tooltip="matTooltip"
                      (mouseenter)="tooltip.show()"
                      matTooltip= "Parts Distributor"
                      matTooltipPosition="above"
                      (click)="salesOrders(d.DESCRIPTION,d.PLANNED,'PD','')">
                      PD : {{d.PDPieces | number:'1.0-0'}}
                  </mat-list-item>
                </div>
                <div>
                  <mat-list-item *ngIf="d.AWPieces >0"
                      #tooltip="matTooltip"
                      (mouseenter)="tooltip.show()"
                      matTooltip= "TBD"
                      matTooltipPosition="above"
                      (click)="salesOrders(d.DESCRIPTION,d.PLANNED,'AW','')">
                      AW : {{d.AWPieces | number:'1.0-0'}}
                  </mat-list-item>
                </div>
                <div>
                  <mat-list-item *ngIf="d.RFPieces >0"
                      #tooltip="matTooltip"
                      (mouseenter)="tooltip.show()"
                      matTooltip= "Consumer"
                      matTooltipPosition="above"
                      (click)="salesOrders(d.DESCRIPTION,d.PLANNED,'RF','')">
                      RF : {{d.RFPieces | number:'1.0-0'}}                  
                  </mat-list-item>
                </div>
                <div>
                  <mat-list-item *ngIf="d.EXPieces >0"
                      #tooltip="matTooltip"
                      (mouseenter)="tooltip.show()"
                      matTooltip= "Export"
                      matTooltipPosition="above"
                      (click)="salesOrders(d.DESCRIPTION,d.PLANNED,'EX','')">
                      EX : {{d.EXPieces | number:'1.0-0'}}
                  </mat-list-item>
                </div>
                <div>
                  <mat-list-item *ngIf="d.NCPieces >0"
                      #tooltip="matTooltip"
                      (mouseenter)="tooltip.show()"
                      matTooltip="LDC"
                      matTooltipPosition="above"
                      (click)="salesOrders(d.DESCRIPTION,d.PLANNED,'NC','')">
                      NC : {{d.NCPieces | number:'1.0-0'}}
                  </mat-list-item>
                </div>
                <div>
                  <mat-list-item *ngIf="d.OTHERPieces >0">
                    Other : {{d.OTHERPieces | number:'1.0-0'}}
                  </mat-list-item>
                </div>
              </mat-list>
            </div>
          </ng-template>
        </mat-card-content>
    </mat-expansion-panel>
  </mat-card>
</div>
