import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { PackMetricTotalService, PackTotal } from '../services/pack-metric-total.service';


@Component({
  selector: 'app-pack-metric-total',
  templateUrl: './pack-metric-total.component.html',
  styleUrls: ['./pack-metric-total.component.scss']
})
export class PackMetricTotalComponent implements OnInit {
  displayedColumns: string[] = ["HOUR", "LINES", "USERS", "RATE"];
  dataSourceTable: MatTableDataSource<any>;
  dataSource: any; // this datasource is used for the graph
  // instantiate Observable and Subscription
  PackMetricTotalObservable: Observable<any>;
  PackMetricTotalSubscription: Subscription;
  // checking if view is mobile
  isMobile: boolean;
  cardViewData: PackTotal[]
  // variable for calculating the total lines
  totalLines: number = 0;
  chartData: any;

  constructor(private PackMetricTotalService: PackMetricTotalService,
    private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((result) => {
      if (result.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    })
  }

  ngOnInit() {
    this.dataSourceTable = new MatTableDataSource<PackTotal[]>();
    this.dataSource = {};
    this.getPackMetricTotalData();
  }

  getPackMetricTotalData(): void {
    if (this.PackMetricTotalSubscription) this.PackMetricTotalSubscription.unsubscribe()
    this.PackMetricTotalSubscription = this.PackMetricTotalService.getPackMetricTotalData().subscribe((tableData) => {
      this.dataSourceTable = tableData.parts;
      this.cardViewData = tableData.parts;
      this.sumTotalLines();

      let dataForChart = []
      for (var i = 0; i < Object.keys(this.dataSourceTable).length; i++) {
        dataForChart.push({ label: this.dataSourceTable[i].Hour, value: this.dataSourceTable[i].Rate })
      }
      this.formatDataForTable(dataForChart);
    })
  }

  formatDataForTable(chartData: any[]): void {
    this.dataSource = {
      chart: {
        caption: "Rate Per Hour",
        xAxisName: "Hour",
        yAxisName: "Rate",
        theme: "fint",
        valueFontSize: "14",
        decimals: "1",
        valueFontBold: "1"
      },
      data: chartData
    }
  }


  ngOnDestroy() {
    this.PackMetricTotalSubscription.unsubscribe();
  }

  sumTotalLines(): void {
    var i: number;
    this.totalLines = 0;
    // Object.keys(this.dataSource).length is the length of the table
    for (i = 0; i < Object.keys(this.dataSourceTable).length; i++) {
      this.totalLines += this.dataSourceTable[i].Lines
    }
  }

}
