<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav mode="over" closed>
    <mat-toolbar color="primary" [ngStyle]="{'background': productionMode ? '#0277BD' : 'green'}"> Menu </mat-toolbar>
      <mat-nav-list>
        <a mat-list-item routerLink="/home" (click)="closeSideNav()"> Home </a>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Pre-Pack </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/prepackmetric/Jeffersonville Warehouse" (click)="closeSideNav()"> Metric </a>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> Build Location </mat-panel-title>
            </mat-expansion-panel-header>
            <a mat-list-item routerLink="/prepacksummary/ " (click)="closeSideNav()"> Build Location - All</a>
            <a mat-list-item routerLink="/prepacksummary/In Process" (click)="closeSideNav()"> Build Location - In Process </a>
            <a mat-list-item routerLink="/prepacksummary/Scheduled" (click)="closeSideNav()"> Build Location - Scheduled </a>
          </mat-expansion-panel>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Receiving </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/putawaymetric/Jeffersonville Warehouse" (click)="closeSideNav()"> Put-Away </a>
          <a mat-list-item routerLink="/receivinglpnuser/Jeffersonville Warehouse" (click)="closeSideNav()"> LPN Count </a>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Order Chase </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/shipping/Jeffersonville Warehouse" (click)="closeSideNav()"> Jeffersonville </a>
          <a mat-list-item routerLink="/shipping/River Ridge Warehouse" (click)="closeSideNav()"> River Ridge </a>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Speed of Shipment </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/sos/Jeffersonville Warehouse" (click)="closeSideNav()"> Jeffersonville </a>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Picking </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLink="/pickmetricuser" (click)="closeSideNav()"> User </a>
          <a mat-list-item routerLink="/pickmetrictotal" (click)="closeSideNav()"> Total </a>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Packing </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item visibility:visible routerLink="/packmetrictotal" (click)="closeSideNav()"> Total </a>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> User Productivity </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item visibility:visible routerLink="/userproductivity" (click)="closeSideNav()"> Summary </a>
        </mat-expansion-panel>

      </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" [ngStyle]="{'background': productionMode ? '#0277BD' : 'green'}">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="sidenav.toggle()">
        <mat-icon style="font-size: 32px"  aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <div class="title-spacing">
        <div class="mat-title" style="margin: 0px; font-size: 18px">{{subtitle | uppercase}}</div>
        <div class="mat-small" style="position:relative; margin: 0px; padding-left: 15px;">{{title}}</div>
      </div>
      <div class="container"> 
        <mat-list style="display:flex; flex-direction: row; justify-content: flex-end;"> 
          <span *ngIf="showDatepicker">
          <gea-mes-datepicker
            class="dateRangeBut"
            matTooltip="Select Date" 
            (onDateSelect)="onDateSelection($event)" 
            (onDateInit)="onDateSelection($event)"
            singleDate="false" 
            showLabel="true"
            showCurrentShift="false"
            defaultRange="Today"
            showRangeLabel="true"
            autoApply="true"
            index="1">
          </gea-mes-datepicker>
          </span>
          <button
            type="button" 
            mat-icon-button 
            (click)="goHome()">
            <mat-icon style="font-size: 32px;"  area-label="Home Icon">home</mat-icon> 
          </button>
        </mat-list>
      </div>
    </mat-toolbar>
  <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
