import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { DateFilter } from './models/partsWarehouse';
import { DateRangeSelectorService } from './services/date-range-selector.service';
import { DatePickerVisibilityService } from './services/date-picker-visibility.service';
import {MatSidenav} from '@angular/material/sidenav';
import { environment } from 'src/environments/environment';
import { appinfo } from './appinfo';

const APP_TITLE:string = 'Brilliant Parts';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  
  title:string = APP_TITLE;
  subtitle:string = APP_TITLE;
  
  today: Date = new Date();
  productionMode: boolean;
  Version: any;
  showDatepicker: boolean=false;

  constructor (
    private router: Router,
    private titleService: Title,
    private dateRangeSelectorService: DateRangeSelectorService,
    private visService: DatePickerVisibilityService
  )
  {
    this.productionMode = environment.production
    this.Version = appinfo.version;
    console.log("Version: ", this.Version)
  }

  ngOnInit() {
    this.router.events.pipe(
      filter((d) => d instanceof RoutesRecognized)
    ).subscribe((r:RoutesRecognized) => {
      let routeData = r.state.root.firstChild.data;
      let rTitle = routeData['title'] || APP_TITLE;
      this.titleService.setTitle(`${rTitle} - ${APP_TITLE}`);
      this.title=rTitle;
      let p = r.state.root.firstChild.paramMap;
      let site =p.get('siteId');
      this.subtitle = site ? site : APP_TITLE;
    });

    this.visService.datePickerVisibility.subscribe ( visBool => {
      this.showDatepicker = visBool;
    });
  }

  onDateSelection(e) {
    console.log("Date selected", e);
    let dateSelection:DateFilter = {
      start: e.start,
      end: e.end
    }

    this.dateRangeSelectorService.selectDateRange(dateSelection);
  }

  closeSideNav(){
    this.sidenav.close()
  }

  goHome(){
    this.router.navigate(["/home"])
  }

}
