<div *ngIf="!isMobile; else mobileView">
    <mat-tab-group>
        <mat-tab label="Table">
            <ng-template matTabContent>
                <table mat-table [dataSource]="dataSourceTable"> 
                    <ng-container matColumnDef="HOUR">
                        <th mat-header-cell *matHeaderCellDef>Hour</th>
                        <td mat-cell *matCellDef="let element">{{element.Hour}}</td> <!-- Case sensitive: Defined in Lambda -->
                    </ng-container>

                    <ng-container matColumnDef="LINES">
                        <th mat-header-cell *matHeaderCellDef>Lines</th>
                        <td mat-cell *matCellDef="let element">{{element.Lines}}</td>
                    </ng-container>

                    <ng-container matColumnDef="USERS">
                        <th mat-header-cell *matHeaderCellDef>Users</th>
                        <td mat-cell *matCellDef="let element">{{element.Users}}</td>
                    </ng-container>

                    <ng-container matColumnDef="RATE">
                        <th mat-header-cell *matHeaderCellDef>Rate</th>
                        <td mat-cell *matCellDef="let element">{{element.Rate | number : '1.1-1'}}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
                <mat-card>Total Lines: {{totalLines}}</mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab label="Graph">
            <ng-template matTabContent>
                <div class="graph-positioning">
                    <fusioncharts
                        width="1000" 
                        height="600" 
                        type="line" 
                        [dataSource]="dataSource">
                    </fusioncharts>
                </div>    
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #mobileView>
    <mat-tab-group>
        <mat-tab label="Table">
            <ng-template matTabContent>                
                <mat-card class="hourCard" *ngFor="let element of cardViewData">
                    <mat-card-header>
                        <mat-card-title>Hour: {{element.Hour}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content class="fx-row-space-evenly-center">
                        <div>
                            <h5>Lines</h5>
                            <h3 class="center-element">{{element.Lines}}</h3>
                        </div>
                        <div>
                            <h5>Users</h5>
                            <h3 class="center-element">{{element.Users}}</h3>
                        </div>
                        <div>
                            <h5>Rate</h5>
                            <h3 class="center-element">{{element.Rate | number : '1.1-1'}}</h3>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card>Total Lines: {{totalLines}}</mat-card>
            </ng-template>
        </mat-tab>
        <mat-tab label="Graph">
            <ng-template matTabContent>
                <div class="graph-positioning">
                    <fusioncharts
                        width="100%" 
                        height="575" 
                        type="line" 
                        [dataSource]="dataSource">
                    </fusioncharts>
                </div>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</ng-template>