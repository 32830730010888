import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable, Subscription} from 'rxjs';
import { LegacyPageEvent as PageEvent, MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { GenericLambdaReturn } from '../models/partsWarehouse';
import { PrepackDetailService, PrePackDetailType } from '../services/prepack-detail.service'
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { DatePickerVisibilityService } from '../services/date-picker-visibility.service';

@Component({
  selector: 'app-prepack-detail',
  templateUrl: './prepack-detail.component.html',
  styleUrls: ['./prepack-detail.component.scss']
})
export class PrepackDetailComponent implements OnInit, OnDestroy {

  dataSource:MatTableDataSource<PrePackDetailType>
  mDataSource: PrePackDetailType[]
  buildLocation: string
  isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
  columns: string[]=["BUILD_LOCATION", "CREATED_DATE_TIME", "WORK_ORDER_ID", "CONDITION", "ITEM", "QTY_TO_BE_BUILT", "QTY_BUILT"];
  filter: string = '';

  private paginator: MatPaginator

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator){
    this.paginator = mp;
    this.dataSource.paginator = this.paginator
  }

  pageEvent: PageEvent;
  length: number;
  pageSize: number = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  subscription: Subscription;

  constructor(
    private service: PrepackDetailService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private datePickVis: DatePickerVisibilityService
  ) { }

  ngOnInit() {
    this.datePickVis.updateDatePickerVis(false)
    this.dataSource = new MatTableDataSource<PrePackDetailType>();
    this.dataSource.sort=this.sort;
    this.buildLocation = this.route.snapshot.paramMap.get('buildLocation');
    this.fetch();
    this.route.paramMap.subscribe (params => {
      this.filter = params.get("filter")
      console.log(this.filter)
      this.applyFilter(this.filter)
    })
  }

  fetch(){
    let d: Observable<GenericLambdaReturn<PrePackDetailType[]>> =
      this.route.paramMap.pipe(
        switchMap((params:ParamMap) =>
        this.service.displayPrepackDetail(this.buildLocation))
      );
    if (this.subscription) this.subscription.unsubscribe();
    this.subscription = d.subscribe((next) => {
      this.dataSource.data=next.parts;
      this.dataSource.sort=this.sort;
      this.mDataSource = this.dataSource.filteredData;
      this.length = this.dataSource.data.length;
    })
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
