import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { SummaryBySiteService, SumByTransportMode } from '../services/summary-by-site.service';
import { DateFilter } from '../models/partsWarehouse';
import { Router } from '@angular/router';
import { DateRangeSelectorService } from '../services/date-range-selector.service';
import { Observable, Subject, ReplaySubject, Subscription } from 'rxjs';
import { switchMap, map, tap } from 'rxjs/operators';
import { DatePickerVisibilityService } from '../services/date-picker-visibility.service';

@Component({
  selector: 'app-rollup-by-xport-mode',
  templateUrl: './rollup-by-xport-mode.component.html',
  styleUrls: ['./rollup-by-xport-mode.component.scss']
})
export class RollupByXportModeComponent implements OnInit {

  //dateFilter= new Subject<DateFilter>();
  completed$: Observable<SumByTransportMode[]>;
  open$: Observable<SumByTransportMode[]>;
  //private dateRangeSub:Subscription;
  constructor(
    private service: SummaryBySiteService,
    private router: Router,
    private dateRangeSelectorService: DateRangeSelectorService,
    private datePickVis: DatePickerVisibilityService
  ) { }


  ngOnInit() {
    this.completed$ = this.dateRangeSelectorService.dateRangeSelected$.pipe(
      tap(df=>console.log("invoke completed",df)),
      switchMap((df:DateFilter) => this.service.completedByTransportMode(df)),
      map(r=>r.parts)
    )
    this.open$ = this.dateRangeSelectorService.dateRangeSelected$.pipe(
      tap(df=>console.log("invoke open",df)),
      switchMap((df:DateFilter) => this.service.openByTransportMode(df)),
      map(r=>r.parts)
    )

    this.datePickVis.updateDatePickerVis(true)
  }

  workerPackMetrics(siteId: string) {
    this.router.navigate(["/workers",siteId]);
  }
  orderChase(siteId: string) {
    this.router.navigate(['/shipping', siteId]);
  }
}
