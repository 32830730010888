import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { PickTotal, PickMetricTotalService } from '../services/pick-metric-total.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { pluck } from 'rxjs/operators';
import { waitForAsync } from '@angular/core/testing';

@Component({
  selector: 'app-pick-metric-total',
  templateUrl: './pick-metric-total.component.html',
  styleUrls: ['./pick-metric-total.component.scss']
})
export class PickMetricTotalComponent implements OnInit {
  displayedColumns: string[] = ["HOUR", "TOTAL_PIECES", "TOTAL_LINES", "PICKERS", "LPH", "PERFORMANCE"];
  dataSourceTable: MatTableDataSource<any>;
  dataSource: any; // this data source will be used for the graph
  chartData: any;
  // instantiate Observable and subscription
  PickMetricTotalObservable: Observable<any>
  PickMetricTotalSubscription: Subscription
  // variables for calculating totals and averages
  summedPieces: number = 0;
  summedLines: number = 0;
  averageLPH: number = 0;
  averagePerformance: number = 0;
  //checks if the screen is in mobile view
  isMobile: boolean;
  cardViewData: PickTotal[]

  constructor(private PickMetricTotalService: PickMetricTotalService,
    private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((result) => {
      if (result.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    })
  }

  ngOnInit() {
    this.dataSourceTable = new MatTableDataSource<PickTotal>();
    this.dataSource = {};
    this.getPickMetricTotalData();
  }

  getPickMetricTotalData(): void {
    if (this.PickMetricTotalSubscription) this.PickMetricTotalSubscription.unsubscribe()
    this.PickMetricTotalObservable = this.PickMetricTotalService.getPickMetricTotalData().pipe(
      pluck("Body")
    )
    this.PickMetricTotalSubscription = this.PickMetricTotalObservable.subscribe((tableData) => {
      this.dataSourceTable = tableData;
      this.cardViewData = tableData
      this.sumTotalPieces();
      this.sumTotalLines();
      this.averageTotalLPH();
      this.averageTotalPerformance();

      let dataForChart = []
      for (var i = 0; i < Object.keys(this.dataSourceTable).length; i++) {
        dataForChart.push({ label: this.dataSourceTable[i].HOUR, value: this.dataSourceTable[i].TOTAL_LINES / this.dataSourceTable[i].PICKERS })
      }
      this.formatDataForChart(dataForChart);
    })
  }

  formatDataForChart(chartData: any[]): void {
    this.dataSource = {
      chart: {
        caption: "Rate Per Hour",
        xAxisName: "Hour",
        yAxisName: "Rate",
        theme: "fint",
        valueFontSize: "14",
        decimals: "1",
        valueFontBold: "1"
      },
      data: chartData
    }
  }

  sumTotalPieces(): void {
    var i: number
    this.summedPieces = 0
    for (i = 0; i < Object.keys(this.dataSourceTable).length; i++) {
      this.summedPieces += this.dataSourceTable[i].TOTAL_PIECES
    }
  }

  sumTotalLines(): void {
    var i: number
    this.summedLines = 0
    for (i = 0; i < Object.keys(this.dataSourceTable).length; i++) {
      this.summedLines += this.dataSourceTable[i].TOTAL_LINES
    }
  }

  averageTotalLPH(): void {
    var i: number;
    this.averageLPH = 0;
    for (i = 0; i < Object.keys(this.dataSourceTable).length; i++) {
      this.averageLPH += this.dataSourceTable[i].LPH
    }
    this.averageLPH = this.averageLPH / i
  }

  averageTotalPerformance(): void {
    var i: number;
    this.averagePerformance = 0;
    for (i = 0; i < Object.keys(this.dataSourceTable).length; i++) {
      this.averagePerformance += this.dataSourceTable[i].PERFORMANCE;
    }
    this.averagePerformance = this.averagePerformance / i
  }

  ngOnDestroy() {
    this.PickMetricTotalSubscription.unsubscribe();
  }

}
