<form [formGroup]="shiftFormGroup">

      <div mat-dialog-content>
          <div class="fx-row-space-between-center">
              <div>Shift Date:</div>
               <gea-mes-datepicker
                  class="dateRangeBut"
                  matTooltip="Select Date" 
                  (onDateSelect)="onDateSelection($event)" 
                  (onDateInit)="onDateSelection($event)"
                  singleDate="true" 
                  showLabel="true"
                  showCurrentShift="false"
                  defaultRange="Today"
                  showRangeLabel="true"
                  autoApply="true"
                  index="2">
              </gea-mes-datepicker>
          </div>
  
          <div class="fx-row-space-between-center">
              <div style="margin-right: 10px">Shift:</div>
              <mat-form-field style="margin-right: 10px" [style.width.px]="130">
                  <mat-select formControlName="Shift">
                      <mat-option *ngFor="let d of shiftList" [value]="d">{{d}}</mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
      </div>
      
      <div mat-dialog-actions class="fx-row-end" >
          <button mat-raised-button color="primary" (click)="onNoClick()">Cancel</button>
          <button mat-raised-button color="primary" (click)="changeShift()">Apply</button>
      </div>
  </form>