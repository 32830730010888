  <mat-card>
    <mat-card-title><mat-icon>mail</mat-icon>Parcel</mat-card-title>
    <mat-card-content>
      <div class="container">
        <table class="fx-flex-100">
          <tr>
            <th>Orders</th>
            <th>Items</th>
            <th>Pieces</th>
          </tr>
          <tr>
            <th>{{metrics.Parcel_Orders | number:'1.0-0'}}</th>
            <th>{{metrics.Parcel_Items | number:'1.0-0'}}</th>
            <th>{{metrics.Parcel_Pieces | number:'1.0-0'}}</th>
          </tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title><mat-icon>local_shipping</mat-icon>Truck</mat-card-title>
    <mat-card-content>
      <div class="container">
        <table class="fx-flex-100">
          <tr>
            <th>Orders</th>
            <th>Items</th>
            <th>Pieces</th>
          </tr>
          <tr>
            <th>{{metrics.Truck_Orders | number:'1.0-0'}}</th>
            <th>{{metrics.Truck_Items | number:'1.0-0'}}</th>
            <th>{{metrics.Truck_Pieces | number:'1.0-0'}}</th>
          </tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
