/*
* Description: Static Application Information
*
* History:
*   06/12/2023  A Kuprion   ITMFG-34260 Initial version, needed for Angular upgrade as package.json can no longer be read.
*
**/
export const appinfo = {
    'name': 'parts',
    'version': '1.0.0'
}