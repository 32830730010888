<form class="hourlyView" [formGroup] = "hourlyViewFormGroup">
  <div class="flex-container">
    <mat-form-field class="hourSelect">
      <mat-label>Hour</mat-label>
      <mat-select formControlName="hourControl">
        <mat-option *ngFor="let hour of hours" [value]="hour.value">
          {{hour.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
  

  <table mat-table [dataSource]="dataSource" matSort matSortActive="Performance"
  matSortDirection="asc" matSortStart="desc" *ngIf="!isMobile; else elseBlock" matSortDisableClear>
  
    <!-- Picker Column -->
    <ng-container matColumnDef="Picker">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Picker</th>
      <td mat-cell *matCellDef="let element">{{element.Picker}}</td>
    </ng-container>
  
    <!-- Total Lines Column -->
    <ng-container matColumnDef="TOTAL_LINES">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Lines </th>
      <td mat-cell *matCellDef="let element"> {{element.TOTAL_LINES}} </td>
    </ng-container>
  
    <!-- Total PCS Column -->
    <ng-container matColumnDef="TOTAL_PIECES">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total PCS </th>
      <td mat-cell *matCellDef="let element"> {{element.TOTAL_PIECES}} </td>
    </ng-container>
  
    <!-- Hours Worked Column -->
    <ng-container matColumnDef="Hours_Worked">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Hours Worked </th>
      <td mat-cell *matCellDef="let element"> {{element.Hours_Worked}} </td>
    </ng-container>
  
    <!-- LPH Column -->
    <ng-container matColumnDef="LPH">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> LPH </th>
        <td mat-cell *matCellDef="let element"> {{element.LPH}} </td>
      </ng-container>
  
    <!-- Performance Column -->
    <ng-container matColumnDef="PERFORMANCE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Performance</th>
        <td mat-cell *matCellDef="let element">{{element.PERFORMANCE | percent}}</td>
      </ng-container>
  

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

<ng-template #elseBlock>

  <mat-card class="pickerCard" *ngFor="let element of cardViewData">
    <mat-card-header>
      <mat-card-title>{{element.Picker}}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="fx-row-space-evenly-center">
      <div>
        <h5>Total Lines</h5>
        <h3 class="center-element">{{element.TOTAL_LINES}}</h3>
      </div>
      <div>
        <h5>Total PCS</h5>
        <h3 class="center-element">{{element.TOTAL_PIECES}}</h3>
      </div>
      <div>
        <h5>Hours Worked</h5>
        <h3 class="center-element">{{element.Hours_Worked}}</h3>
      </div>
      <div>
        <h5>LPH</h5>
        <h3 class="center-element">{{element.LPH}}</h3>
      </div>
      <div>
        <h5>Performance</h5>
        <h3 class="center-element">{{element.PERFORMANCE | percent}}</h3>
      </div>
    </mat-card-content>
  </mat-card>

</ng-template>