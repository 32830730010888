import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { GenericLambdaReturn } from '../models/partsWarehouse';
import { SalesOrdersService, SalesOrders} from '../services/sales-orders.service';
import { DatePickerVisibilityService } from '../services/date-picker-visibility.service';


@Component({
  selector: 'app-sales-orders',
  templateUrl: './sales-orders.component.html',
  styleUrls: ['./sales-orders.component.scss']
})
export class SalesOrdersComponent implements OnInit, OnDestroy {

  dataSource: MatTableDataSource<SalesOrders>
  mDataSource: SalesOrders[]
  custClass: string
  plannedDate: string
  priority: string;
  isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall])
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
  columns: string[]=["CREATION_DATE_TIME_STAMP","PLANNED_SHIP_DATE","PRIORITY",
  "ORDER_TYPE","SHIPMENT_ID","LINES","CARRIER","CUSTOMER_CATEGORY7",
  "LEADING_STS","TRAILING_STS"];
  subscription: Subscription;


  constructor(
    private service: SalesOrdersService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private datePickVis: DatePickerVisibilityService

  ) { }

  ngOnInit() {
    this.datePickVis.updateDatePickerVis(false)
    this.dataSource = new MatTableDataSource<SalesOrders>();
    this.custClass = this.route.snapshot.paramMap.get('custClass')
    this.plannedDate = this.route.snapshot.paramMap.get('plannedDate')
    this.priority = this.route.snapshot.paramMap.get('priority')
    this.fetch();
  }

  fetch(){
    this.subscription =
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
          this.service.displaySalesOrders(params.get('siteId'), this.custClass, this.plannedDate, this.priority)
        ),
        map(next=> {
          this.dataSource.data = next.parts;
          this.mDataSource = next.parts;
        })
      ).subscribe();
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

}
