<div *ngIf="(isScreen$ | async)?.matches"> 
  <table mat-table [dataSource]="dataSource" matSort
      matSortDirection="desc" matSortStart="desc" matSortDisableClear>
    <ng-container matColumnDef="LOCATION">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Build Location</th>
      <td mat-cell 
          *matCellDef="let d"
          (click)="prepackDetail(d.LOCATION, d.CONDITION)">{{d.LOCATION}}</td>
    </ng-container>
    <ng-container matColumnDef="CONDITION" matSortStart="asc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Condition</th>
      <td mat-cell *matCellDef="let d">{{d.CONDITION}}</td>
    </ng-container>
    <ng-container matColumnDef="WORK_ORDERS">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Work Orders</th>
      <td mat-cell *matCellDef="let d">{{d.WORK_ORDERS | number:'1.0-0'}}</td>
    </ng-container>
    <ng-container matColumnDef="PIECES">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pieces</th>
      <td mat-cell *matCellDef="let d">{{d.PIECES | number:'1.0-0'}}</td>
    </ng-container>
    <ng-container matColumnDef="totalWorkOrders"> 
      <th mat-header-cell *matHeaderCellDef> {{totalsObj.WORK_ORDERS | number:'1.0-0'}} </th>
    </ng-container>
    <ng-container matColumnDef="totalPieces"> 
      <th mat-header-cell *matHeaderCellDef> {{totalsObj.PIECES | number:'1.0-0'}} </th>
    </ng-container>
    <ng-container matColumnDef="blank"> 
      <th mat-header-cell *matHeaderCellDef></th>
    </ng-container>
    <ng-container matColumnDef="total"> 
      <th mat-header-cell *matHeaderCellDef>Total</th>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns; sticky:true"></tr>
    <tr mat-header-row *matHeaderRowDef="['total','blank','totalWorkOrders','totalPieces']; sticky:true"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  </table>
</div>
<div *ngIf="(isTablet$ | async)?.matches" class="card-list">
  <div *ngFor="let d of mDataSource | groupBy:'LOCATION'"> 
    <mat-card class="cards card-details" (click)="prepackDetail(d.key, eachD.condition)">
      <mat-card-header>
        <mat-card-title>{{ d.key }}</mat-card-title>
      </mat-card-header>
      <mat-card-content *ngFor="let eachD of d.value">
        <ul>
            <strong>{{ eachD.CONDITION }}</strong>
            <li>Work Orders: {{ eachD.WORK_ORDERS }}</li>
            <li>Pieces: {{ eachD.PIECES }}</li>
        </ul>
      </mat-card-content>
    </mat-card>
  </div>
</div>