import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DateSelectComponent } from '../date-select/date-select.component';
import { ProductivityService } from '../productivity.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  
  datasource:any[];

  subSummary$:Subscription;
  sso:string;
  userName:string;
  hour:number;

  shiftDate:string;
  shift:string;

  displayedColumns: string[] = [ 'username', 'activity_date_time', 'lines', 'pieces', 'item', 'shipment_id', 'work_group', 'total_qty', 'qty_um', 'container_id' ];
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private prodservice:ProductivityService
  ) { }

  ngOnInit(): void {
    this.sso = this.route.snapshot.paramMap.get('sso');
    this.hour = Number(this.route.snapshot.paramMap.get('hour'));
    this.getData();
  }

  ngOnDestroy() {
    if (this.subSummary$) this.subSummary$.unsubscribe();
  }  

  getData():void {
    if (this.subSummary$) this.subSummary$.unsubscribe();

    this.subSummary$ = this.prodservice.getUserDetail(this.sso, this.hour).subscribe(
      (out) => {
        console.log("Retrieved user hourly summary!",out);
        this.datasource = out.Body;
        this.shiftDate = this.prodservice.shiftDate;
        this.shift = this.prodservice.shift;
      }
    )
  }

  changeShiftDate() {
    console.log("changeShiftDate");

    const dialogRef = this.dialog.open(DateSelectComponent, {
      width: '300px',
      maxHeight: '800px',
      data: {
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Close ShiftSelectDialogComponent");
      this.getData();
    });
  }

  navToUserHourSummary() {
    this.router.navigate(['/userproductivity/hour', this.sso]);
  }
}
