<div #prePackMetric class="container">
  <!--<mat-card *ngFor="let d of workOrders | async" (click)="prepackMetricDetail('Jeffersonville Warehouse')">-->
    <mat-card (click)="prepackMetricDetail('Jeffersonville Warehouse')">
    <mat-card-title-group>
      <mat-card-title>Jeffersonville Warehouse</mat-card-title>
    </mat-card-title-group>
    <mat-card-content>
        <table class="fx-flex-100" mat-table [dataSource]="workOrders" matSortdisabled>
            <ng-container matColumnDef="Type">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let d">{{d.Type}}</td>
            </ng-container>
            <ng-container matColumnDef="minProcess">
                <th mat-header-cell *matHeaderCellDef>Oldest In Process</th>
                <td mat-cell *matCellDef="let d">{{d.minProcess ? (d.minProcess | amFromUtc | amDateFormat: 'YYYY-MM-DD' ) : 'None'}}</td>
            </ng-container>
            <ng-container matColumnDef="minScheduled">
                <th mat-header-cell *matHeaderCellDef>Oldest Scheduled</th>
                <td mat-cell *matCellDef="let d">{{d.minScheduled ? (d.minScheduled | amFromUtc | amDateFormat: 'YYYY-MM-DD' ) : 'None'}}</td>
            </ng-container>
            <ng-container matColumnDef="totalOrders">
                <th mat-header-cell *matHeaderCellDef>Total Work Orders</th>
                <td mat-cell *matCellDef="let d">{{d.totalOrders | number:'1.0-0'}}</td>
            </ng-container>            
            <tr mat-header-row *matHeaderRowDef="columns"></tr>
            <tr mat-row *matRowDef="let row; columns: columns;"></tr>               
        </table>           
    </mat-card-content>
  </mat-card>
  <div *ngIf="(isScreen$ | async)?.matches">
    <div class="graph-positioning">
      <div class="mat-elevation-z8 table-container prepack-metric-div" id="enterpriseTable">
        <fusioncharts
        width="45%"
        height="45%"
        type="msspline"
        [chartConfig]=pphChartConfig
        [dataSource]=pphDataSource>
        </fusioncharts>
      </div>
      <div class="mat-elevation-z8 table-container prepack-metric-div" id="enterpriseTable">
        <fusioncharts
        width="45%"
        height="45%"
        type="mscombidy2d"
        [chartConfig]=ordersPastDueChartConfig
        [dataSource]=ordersPastDueDataSource>
        </fusioncharts>
      </div>
    </div>
  </div>
  <div *ngIf="(isTablet$ | async)?.matches">
    <div class="graph-positioning">
      <div class="mat-elevation-z8 table-container prepack-metric-div" id="enterpriseTable">
        <fusioncharts
        width="85%"
        height="85%"
        type="msspline"
        [chartConfig]=pphChartConfig
        [dataSource]=pphDataSource>
        </fusioncharts>
      </div>
      <div class="mat-elevation-z8 table-container prepack-metric-div" id="enterpriseTable">
        <fusioncharts
        width="85%"
        height="85%"
        type="mscombidy2d"
        [chartConfig]=ordersPastDueChartConfig
        [dataSource]=ordersPastDueDataSource>
        </fusioncharts>
      </div>
    </div>
  </div>
</div>
