<form class="hourlyView" [formGroup] = "hourlyViewFormGroup">
  <mat-form-field class="hourSelect">
    <mat-label>Hour</mat-label>
    <mat-select formControlName="hourControl">
      <mat-option *ngFor="let hour of hours" [value]="hour.value">
        {{hour.viewValue}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>

<table class="gea-table" mat-table [dataSource]="dataSource" matSort matSortActive="Orders"
    matSortDirection="desc" matSortStart="desc" matSortDisableClear>
  <ng-container matColumnDef="Name" matSortStart="asc">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
    <td mat-cell *matCellDef="let d">{{d.Name}}</td>
  </ng-container>
  <ng-container matColumnDef="Orders">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Orders</th>
    <td mat-cell *matCellDef="let d">{{d.Orders | number:'1.0-0'}}</td>
  </ng-container>
  <ng-container matColumnDef="LABELS">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Labels</th>
    <td mat-cell *matCellDef="let d">{{d.LABELS | number:'1.0-0'}}</td>
  </ng-container>
  <ng-container matColumnDef="labelsPerShipment">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Labels Per Shipment</th>
    <td mat-cell *matCellDef="let d">{{d.labelsPerShipment | number:'1.1-2'}}</td>
  </ng-container>
  <ng-container matColumnDef="Pieces">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Pieces</th>
    <td mat-cell *matCellDef="let d">{{d.Pieces | number:'1.0-0'}}</td>
  </ng-container>
  <ng-container matColumnDef="piecesPerOrder">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Pieces Per Order</th>
    <td mat-cell *matCellDef="let d">{{d.piecesPerOrder | number:'1.1-1'}}</td>
  </ng-container>

  <!-- Second table header with totals -->
  <ng-container matColumnDef="totalOrders"> 
    <th mat-header-cell *matHeaderCellDef>{{totalsObj.Orders | number:'1.0-0'}}</th>
  </ng-container>
  <ng-container matColumnDef="totalLines"> 
    <th mat-header-cell *matHeaderCellDef>{{totalsObj.LABELS | number:'1.0-0'}}</th>
  </ng-container>
  <ng-container matColumnDef="totalLabelsPerShipment"> 
    <th mat-header-cell *matHeaderCellDef>{{totalsObj.labelsPerShipment | number:'1.1-2'}}</th>
  </ng-container>
  <ng-container matColumnDef="totalPieces"> 
    <th mat-header-cell *matHeaderCellDef>{{totalsObj.Pieces | number:'1.0-0'}}</th>
  </ng-container>
  <ng-container matColumnDef="totalPiecesPerOrder"> 
    <th mat-header-cell *matHeaderCellDef>{{totalsObj.piecesPerOrder | number:'1.1-1'}}</th>
  </ng-container>
  <ng-container matColumnDef="label"> 
    <th mat-header-cell *matHeaderCellDef>Total</th>
  </ng-container>
  <ng-container matColumnDef="blank"> 
    <th mat-header-cell *matHeaderCellDef></th>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-header-row 
    *matHeaderRowDef="['label', 'totalOrders', 'totalLines', 'totalLabelsPerShipment', 'totalPieces', 'totalPiecesPerOrder']"
    [ngClass]="{warning : (totalsObj.LABELS / totalsObj.Orders) > 1.25}"> </tr>
  <tr mat-row *matRowDef="let row; columns: columns;" [ngClass]="{warning : (row.LABELS / row.Orders) > 1.25}" (click)="shipmentDetails(row.SSO)"></tr>
</table>
