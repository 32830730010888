import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { WorkerShipmentDetail, ShipmentDetails } from '../services/worker-shipment-detail.service';
import { ActivatedRoute } from '@angular/router';
import { switchMap, tap, map } from 'rxjs/operators';
import { Observable} from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { hourModel } from './worker-pack-model'
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { DatePickerVisibilityService } from '../services/date-picker-visibility.service';
import { DateFilter } from '../models/partsWarehouse';
import { DateRangeSelectorService } from '../services/date-range-selector.service';

@Component({
  selector: 'app-worker-pack-detail',
  templateUrl: './worker-pack-detail.component.html',
  styleUrls: ['./worker-pack-detail.component.scss'],
})
export class WorkerDetailComponent implements OnInit, OnDestroy {
  trackByIndex = index => index;
  hours: hourModel[] = [
    {value: -2, viewValue: 'All Day'},
    {value: -1, viewValue: 'Current Hour'}
  ];
  public hourlyViewFormGroup = new UntypedFormGroup({
    hourControl: new UntypedFormControl()
  })
  hourObj: hourModel;
  timeToRefreshHourArray: number;
  hourlyView: boolean;
  timeFrame: any;
  todayDate: string
  selectedHour: number;
  data$:Observable<ShipmentDetails[]>;
  columns: string[]=["ERP_ORDER","CUST_CLASS","Item","ITEM_HEIGHT","ITEM_WIDTH","ITEM_LENGTH","VOLUME","WEIGHT"]; 
  @ViewChild(MatSort) sort: MatSort;
  siteId: string;
  sso: string;

  constructor(
    private service: WorkerShipmentDetail,
    private route: ActivatedRoute,
    private datePickVis: DatePickerVisibilityService,
    private dateRangeSelectorService: DateRangeSelectorService,
  ) {
    dayjs.extend(timezone);
    dayjs.extend(utc);
    this.todayDate = dayjs().format('YYYY MM DD');
    this.hourlyViewFormGroup.controls['hourControl'].setValue(-2);
    this.timeToRefreshHourArray = 360000000
  }

  ngOnInit() {
    this.datePickVis.updateDatePickerVis(false)
    this.siteId = this.route.snapshot.paramMap.get('siteId'); 
    this.sso = this.route.snapshot.paramMap.get('sso');
    this.fetch();
    this.populateHourDropDown();
    setInterval(this.populateHourDropDown, this.timeToRefreshHourArray)

    this.hourlyViewFormGroup.valueChanges.subscribe( val => {
      this.fetch(val["hourControl"])
    })
    this.datePickVis.updateDatePickerVis(true)
  }

  ngAfterViewInit() {

  }

  populateHourDropDown(){
    let now = dayjs().hour();
    let hour = 0
    let minNow = dayjs().minute();

    this.timeToRefreshHourArray = (((60 - minNow) * (60)) * 1000)

    this.hours.length = 0

    this.hours.push({value: -2, viewValue: 'All Day'})
    this.hours.push({value: -1, viewValue: 'Current Hour'})

    for (hour = 0; hour <= now; hour++){
      let dateVal = (dayjs().hour(hour)).tz('America/New_York');
      let dateViewVal = dayjs().hour(hour);

      this.hourObj = {
        value: dateVal.hour(),
        viewValue: dateViewVal.startOf('hour').format('h:mm A')
      }

      this.hours.push(this.hourObj)
    }
  }

  timeTransformer(time: number): number{
    switch(time) {
      case(-1): return dayjs().tz('America/New_York').startOf('hour').hour();
      case(-2): return null;
      default: return time;
    }
  }

  fetch(hour?: number) {
    let timeFrame = this.timeTransformer(hour)

    this.data$ =
    this.dateRangeSelectorService.dateRangeSelected$.pipe(
      tap(df=>console.log("invoke worker pack metrics",df)),
      switchMap((df: DateFilter) =>
        this.service.shipmentDetail(this.siteId, df, timeFrame, this.sso)
      ),map(r=>r.parts)
    )
  }

  ngOnDestroy() {

  }

}
